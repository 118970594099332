<template>
  <div>
    <v-stepper
      :style="{ backgroundColor: backgroundcolor }"
      v-model="e1"
      class="booking--stepper--header hidden-sm-and-down"
      alt-labels
    >
      <div class="booking--stepper--container">
        <v-container
          class="d-flex flex-column justify-center"
          style="height: 100%"
        >
          <ul class="progressbar">
            <li :class="returnClass(1)">
              <span class="text--regular--14"
                >ระบุวันที่และพื้นที่<br />นิทรรศการ/แถลงข่าว</span
              >
            </li>
            <li :class="returnClass(2)">
              <span class="text--regular--14">ยืนยันพื้นที่ในการขอใช้</span>
            </li>
            <li :class="returnClass(3)">
              <span class="text--regular--14">ระบุรายละเอียดผู้ขอใช้</span>
            </li>
            <li :class="returnClass(4)">
              <span class="text--regular--14">ขอใช้พื้นที่สำเร็จ</span>
            </li>
          </ul>
        </v-container>
      </div>
      <v-container class="booking-process--container--custom">
        <v-stepper-items>
          <!-- <v-stepper-content class="pa-0 pa-sm-0 pa-md-6" step="1"> -->
          <v-stepper-content class="pa-0 pa-sm-0" step="1">
            <div v-if="requestType == 'DATE'">
              <DateSpecify :isElevation="true" />
              <AreaSpecify
                :backToPreviousStep="backToPreviousStep"
                @clicked="onClickStep"
              />
            </div>
            <div v-if="requestType == 'AREA'">
              <!-- <AreaSpecify @clicked="onClickStep" /> -->
              <AreaSpecify
                :backToPreviousStep="backToPreviousStep"
                @clicked="onClickStep"
              />
            </div>
          </v-stepper-content>

          <v-stepper-content class="pa-0 pa-sm-0 pa-md-6" step="2">
            <Confirmation @clicked="onClickStep" />
          </v-stepper-content>

          <v-stepper-content class="pa-0 pa-sm-0 pa-md-6" step="3">
            <ApplicantDetail @clicked="onClickStep" />
          </v-stepper-content>
          <v-stepper-content class="pa-0 pa-sm-0 pa-md-6" step="4">
            <Summary @clicked="onClickStep" />
          </v-stepper-content>
        </v-stepper-items>
      </v-container>
    </v-stepper>
    <v-container class="hidden-md-and-up booking-process--container--custom">
      <!-- <VList> </VList> -->
      <v-list-item-content class="bg-active-content pt-0">
        <v-stepper alt-labels v-model="e1" class="booking--stepper--header">
          <div class="booking--stepper--container">
            <v-stepper-header class="booking--stepper--header">
              <div class="booking--process--stepper--mobile">
                <ul class="progressbar">
                  <li :class="returnClass(1)">
                    <span class="text--regular--14"
                      >ระบุวันที่และพื้นที่<br />นิทรรศการ/แถลงข่าว</span
                    >
                  </li>
                  <li :class="returnClass(2)">
                    <span class="text--regular--14"
                      >ยืนยันพื้นที่ในการขอใช้</span
                    >
                  </li>
                  <li :class="returnClass(3)">
                    <span class="text--regular--14"
                      >ระบุรายละเอียดผู้ขอใช้</span
                    >
                  </li>
                  <li :class="returnClass(4)">
                    <span class="text--regular--14">ขอใช้พื้นที่สำเร็จ</span>
                  </li>
                </ul>
              </div>
            </v-stepper-header>
          </div>
          <v-container class="booking-process--container--custom">
            <v-stepper-items>
              <v-stepper-content class="pa-0 pa-sm-0 pa-md-6" step="1">
                <div v-if="requestType == 'DATE'">
                  <DateSpecify :isElevation="true" />
                  <AreaSpecify
                    :backToPreviousStep="backToPreviousStep"
                    @clicked="onClickStep"
                  />
                </div>
                <div v-if="requestType == 'AREA'">
                  <AreaSpecify
                    :backToPreviousStep="backToPreviousStep"
                    @clicked="onClickStep"
                  />
                </div>
              </v-stepper-content>

              <v-stepper-content class="pa-0 pa-sm-0 pa-md-6" step="2">
                <Confirmation @clicked="onClickStep" />
              </v-stepper-content>

              <v-stepper-content class="pa-0 pa-sm-0 pa-md-6" step="3">
                <ApplicantDetail @clicked="onClickStep" />
              </v-stepper-content>
              <v-stepper-content class="pa-0 pa-sm-0 pa-md-6" step="4">
                <Summary @clicked="onClickStep" />
              </v-stepper-content>
            </v-stepper-items>
          </v-container>
        </v-stepper>
      </v-list-item-content>
    </v-container>
    <v-dialog
      id="modal-template"
      v-model="backHistoryDialog"
      max-width="780"
      :retain-focus="false"
      persistent
    >
      <v-card class="pa-10 confirmation-popup-card">
        <v-card-title class="confirmation-popup-title">
          <p class="mb-0 popup-comfirm-delete-text">
            ระบบจะทำการลบข้อมูลการจองที่ท่านเลือกไว้ ท่านต้องการจะทำต่อหรือไม่
          </p>
        </v-card-title>
        <v-card-actions class="popup-comfirm-delete">
          <v-btn
            elevation="0"
            class="popup-comfirm-delete-button mb-2"
            outlined
            rounded
            color="black"
            @click="discardChanges()"
            width="200"
          >
            <p class="mb-0 black--text">
              ตกลง
            </p>
          </v-btn>
          <v-btn
            elevation="0"
            rounded
            class="popup-comfirm-delete-button mb-2"
            color="primary"
            @click="closeDialog()"
            width="200"
          >
            <p class="mb-0 black--text ">
              ยกเลิก
            </p>
          </v-btn>
        </v-card-actions>
        <v-btn
          :ripple="false"
          fab
          text
          small
          color="grey darken-2"
          @click="closeDialog()"
          class="confirmation--closeicon"
        >
          <v-icon large :ripple="false">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DateSpecify from "@/components/DateSpecify.vue";
import AreaSpecify from "@/components/AreaSpecify.vue";
import Confirmation from "@/components/Confirmation.vue";
import ApplicantDetail from "@/components/ApplicantDetail.vue";
import Summary from "@/components/Summary.vue";
// import VList from "../components/VList.vue";

export default {
  name: "bookingProcess",
  components: {
    DateSpecify,
    AreaSpecify,
    Confirmation,
    ApplicantDetail,
    Summary
    // VList
  },
  data: function() {
    return {
      e1: 1,
      backToPreviousStep: false, //Start : Sasiprapa B. : 2021-04-23
      backHistoryDialog: false, //Start : Sasiprapa B. : 2021-04-23
      goToRentSpaceCriteria: false, //Start : Sasiprapa B. : 2021-04-23
      currentRoute: null //Start : Sasiprapa B. : 2021-04-23
    };
  },
  // Start : Sasiprapa B. : 2021-05-05
  beforeRouteLeave(to, from, next) {
    // this.currentRoute = to.name;
    this.currentRoute = to.fullPath;
    if (
      (to.name == "rentspacecriteria" && from.name == "bookingprocess") ||
      (to.name == "profile" && from.name == "bookingprocess") ||
      (to.name == "reservationshistory" && from.name == "bookingprocess") ||
      (to.name == "login" &&
        from.name == "bookingprocess" &&
        !this.goToRentSpaceCriteria)
    ) {
      if (this.areaCart.length > 0 && this.e1 !== 4) {
        this.backHistoryDialog = true;
        next("/bookingprocess");
        // next(false);
      } else {
        next();
        if (to.name == "reservationshistory") {
          this.setBackgroundFormStepperScreen(0);
        }
      }
    } else {
      this.backHistoryDialog = false;
      next();
      if (to.name == "reservationshistory") {
        this.setBackgroundFormStepperScreen(0);
      }
    }
  },
  // End : Sasiprapa B. : 2021-05-05
  computed: mapState({
    requestType: state => {
      return state.rentspacecriteria.requestType;
    },
    backgroundcolor: state => {
      return state.app.backgroundcolor;
    },
    // Start : Sasiprapa Ban. : 2021-05-05
    areaCart: state => {
      return state.areaspecify.areaCart;
    }
    // End : Sasiprapa Ban. : 2021-05-05
  }),
  mounted() {
    if (
      !this.$store.state.rentspacecriteria.applicantSelected ||
      !this.$store.state.rentspacecriteria.rentSpaceCriteriaData
    ) {
      this.$router.push("/rentspacecriteria");
    }

    // Start : Sasiprapa B.: 2021-04-25
    this.setBackgroundBodyByStepper();
    // End : Sasiprapa B.: 2021-04-25
  },
  methods: {
    // Start : Sasiprapa B.: 2021-04-25 : Set Background and clear Area Cart
    ...mapActions("app", ["setBackgroundFormStepperScreen"]),
    ...mapActions("areaspecify", [
      "getAreaCart",
      "setAreaCardSelected",
      "setAreaConfirmationSelected" //Start : Sasiprapa B. 2021-04-24
    ]),
    // End : Sasiprapa B.: 2021-04-25 : Set Background and clear Area Cart
    onClickStep(type) {
      if (type === "BACK") {
        this.e1 = this.e1 -= 1;
        this.backToPreviousStep = true; //Start : Sasiprapa B. : 2021-04-23
        window.scrollTo(0, 0);
        if (
          this.e1 === 1 &&
          this.$store.state.rentspacecriteria.requestType === "DATE"
        ) {
          document.documentElement.style.overflow = "hidden";
        }
      } else if (type === "NEXT") {
        this.e1 = this.e1 += 1;
        window.scrollTo(0, 0);
        this.backToPreviousStep = false; //Start : Sasiprapa B. : 2021-04-23
      }
      this.setBackgroundBodyByStepper(); //Start : Sasiprapa B. : 2021-04-25
    },
    returnClass(steep) {
      if (this.e1 === steep) {
        return "active nextstep";
      } else if (steep < this.e1) {
        return "active";
      } else {
        return "";
      }
    },
    // Start : Sasiprapa B.: 2021-04-25
    setBackgroundBodyByStepper() {
      this.setBackgroundFormStepperScreen(this.e1);
    },
    closeDialog() {
      this.backHistoryDialog = false;
      this.setBackgroundFormStepperScreen(this.e1);
      //
    },
    discardChanges() {
      // Clear Store Are
      this.setAreaConfirmationSelected([]);
      this.setAreaCardSelected([]);
      //  Set Router
      this.backHistoryDialog = false;
      this.goToRentSpaceCriteria = true;
      // this.$router.push(`/${this.currentRoute}`);
      this.$router.push(this.currentRoute);
      if (this.currentRoute === "rentspacecriteria") {
        this.setBackgroundBodyByStepper();
      }
    }
    // End : Sasiprapa B.: 2021-04-25
  }
};
</script>

<style scoped>
.booking--stepper--container {
  background-color: var(--v-primary);
  max-width: 100%;
  height: 154px;
}

.booking--stepper--header {
  /* background: white; */
  box-shadow: none;
}

@media (max-width: 959px) {
  .booking--process--stepper--mobile {
    margin-top: 39px;
    width: 100%;
    margin-bottom: 27px;
  }

  .progressbar {
    counter-reset: step;
  }

  .progressbar li {
    list-style-type: none;
    width: 25%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #282828;
  }

  .progressbar li:before {
    width: 21px;
    height: 21px;
    content: counter(step);
    counter-increment: step;
    line-height: 25px;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: #dcb542;
    color: #dcb542;
    position: relative;
    z-index: 1;
  }

  .progressbar li.active:before {
    width: 21px;
    height: 21px;
    content: counter(step);
    counter-increment: step;
    line-height: 25px;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
    color: white;
    position: relative;
    z-index: 1;
  }

  .progressbar li:after {
    width: 100%;
    height: 5px;
    content: "";
    position: absolute;
    background-color: #dcb542;
    top: 8px;
    left: -50%;
    z-index: 0;
  }

  .progressbar li:first-child:after {
    content: none;
  }

  .progressbar li.active {
    color: #282828;
  }

  .progressbar li.active + li:after {
    background-color: white;
  }

  .progressbar li.active.nextstep + li:after {
    background-color: #dcb542;
  }

  .progressbar li.nextstep:before {
    width: 20px;
    height: 20px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
    color: white;
    position: relative;
    box-shadow: 0 0 0 6px hsl(1deg 14% 90%);
    z-index: 1;
  }
}

@media (min-width: 960px) {
  .progressbar {
    counter-reset: step;
  }

  .progressbar li {
    list-style-type: none;
    width: 25%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #282828;
  }

  .progressbar li:before {
    width: 20px;
    height: 20px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: #dcb542;
    color: #dcb542;
    position: relative;
    z-index: 1;
  }

  .progressbar li.active:before {
    width: 20px;
    height: 20px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
    color: white;
    position: relative;
    z-index: 1;
  }

  .progressbar li:after {
    width: 100%;
    height: 5px;
    content: "";
    position: absolute;
    background-color: #dcb542;
    top: 8px;
    left: -50%;
    z-index: 0;
  }

  .progressbar li:first-child:after {
    content: none;
  }

  .progressbar li.active {
    color: #282828;
  }

  .progressbar li.active + li:after {
    background-color: white;
  }

  .progressbar li.active.nextstep + li:after {
    background-color: #dcb542;
  }

  .progressbar li.nextstep:before {
    width: 20px;
    height: 20px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
    color: white;
    position: relative;
    box-shadow: 0 0 0 6px hsl(1deg 14% 90%);
    z-index: 1;
  }
}
/* Pop up */
.confirmation-popup-card {
  box-shadow: 0px 0px 15px #496c7733 !important;
  border-radius: 10px;
}
.confirmation-popup-title {
  display: flex;
  justify-content: center;
}
.popup-comfirm-delete-text {
  font-weight: 700;
  font-size: 21px;
}
.popup-comfirm-delete-text {
  font-weight: 700;
  font-size: 21px;
}

.popup-comfirm-delete-button {
  font-weight: 500;
  font-size: 14px;
}

.popup-comfirm-delete {
  display: flex;
  justify-content: center;
}
.confirmation--closeicon {
  position: absolute;
  top: 0px;
  right: 0px;
}
.booking-process--container--custom {
  padding-top: 0px;
}
@media (max-width: 480px) {
  .booking-process--container--custom {
    padding: 0 !important;
    background-color: #f7f7f8 !important;
  }
}
</style>
