<template>
  <v-row class="popup-calendar-content">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1000px"
      style="pos; z-index: 99999; max-height: 100%;"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
          class="popup-calendar-space-request"
          v-if="!isShowDatePicker && !editArea"
          @click="initPupupHistory"
        >
          ดูการขอใช้พื้นที่รายปี
        </v-btn>
        <!-- Start : Sasiprapa B. : 2021-05-05 -->
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
          class="popup-calendar-edit-btn"
          v-if="isShowDatePicker && editArea"
          @click="initPupupHistory"
        >
          แก้ไข
        </v-btn>
        <!-- End : Sasiprapa B. : 2021-05-05 -->

        <v-btn
          v-bind="attrs"
          v-on="on"
          v-if="requestType == 'AREA' && isShowDatePicker && !editArea"
          width="70%"
          rounded
          color="primary"
          dark
          elevation="0"
          class="popup-calendar-select-are-request-btn"
          @click="initPupupHistory"
        >
          <p class="popup-calendar-select-are-request-btn-text">
            เลือกขอใช้พื้นที่
          </p>
        </v-btn>
      </template>

      <v-card
        elevation="0"
        class="pr-5 pl-5 pt-0 pb-0 popup-calendar-card"
        min-height="632"
      >
        <v-row class="mt-0 mb-0 fill-height">
          <v-col>
            <v-sheet
              class="popup-calendar-space-request-container"
              v-if="requestType == 'DATE'"
            >
              <v-toolbar flat class="popup-calendar-space-request-container">
                <v-row class="popup-calendar-space-request-section">
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    class="popup-calendar-space-request-room-name"
                  >
                    <p class="popup-calendar-title">
                      {{ roomName }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    md="8"
                    class="popup-calendar-space-request-select-month"
                  >
                    <div class="popup-calendar-space-request-select-container">
                      <v-btn
                        text
                        small
                        color="grey darken-2"
                        @click="prev"
                        outlined
                        min-width="35"
                        max-width="35"
                      >
                        <v-icon small>
                          mdi-chevron-left
                        </v-icon>
                      </v-btn>
                      <v-toolbar-title
                        class="popup-calendar-space-request-select-container-title"
                      >
                        <p
                          class="mb-0 popup--calendar--text--title popup-calendar-title"
                        >
                          {{ momentFormatDate() }}
                        </p>
                      </v-toolbar-title>
                      <v-btn
                        text
                        small
                        color="grey darken-2"
                        @click="next"
                        :disabled="disableButtonNext"
                        outlined
                        min-width="35"
                        max-width="35"
                      >
                        <v-icon small>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                <v-btn
                  fab
                  text
                  small
                  color="grey darken-2"
                  @click="dialog = false"
                  class="popup--calendar--closeicon"
                >
                  <!-- <v-icon small> -->
                  <v-icon large>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-toolbar>
            </v-sheet>

            <!-- TODO AREA -->

            <!-- new -->

            <v-container
              class="popup-calendar-are-container"
              v-if="requestType === 'AREA'"
            >
              <div v-if="isShowDatePicker">
                <v-row class="mb-0">
                  <v-col sm="12" md="12" class="popup-calendar-title-container">
                    <span class="popup-calendar-title">{{ roomName }}</span>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="3"
                    class="popup-calendar-start-date-container"
                  >
                    <v-menu
                      ref="startDateDialog"
                      v-model="startDateModal"
                      :close-on-content-click="true"
                      :return-value.sync="startDate"
                      transition="scale-transition"
                      offset-y
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrStartDate }">
                        <p class="popup-calendar-start-date-title">
                          วันที่เริ่มจัด
                        </p>
                        <v-text-field
                          :value="formatStartDate"
                          outlined
                          readonly
                          v-bind="attrStartDate"
                          v-on="on"
                          dense
                        >
                          <template v-slot:append>
                            <v-fade-transition leave-absolute>
                              <img
                                width="24"
                                height="24"
                                src="../assets/icons/calendar.svg"
                                alt=""
                              />
                            </v-fade-transition>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        id="date-specify--custom"
                        v-model="startDate"
                        no-title
                        scrollable
                        locale="th-TH"
                        color="primary"
                        :allowed-dates="getAllowedStartDates"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" sm="12" md="2" class="popup-calendar-day">
                    <v-checkbox
                      v-model="oneDateCheckbox"
                      label="จัด 1 วัน"
                      color="#282828"
                      @click="onOneDateChecked"
                    >
                    </v-checkbox>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="3"
                    class="popup-calendar-end-date-container"
                  >
                    <v-menu
                      ref="endDateDialog"
                      v-model="endDateModal"
                      :close-on-content-click="true"
                      :return-value.sync="endDate"
                      transition="scale-transition"
                      offset-y
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrsEndDate }">
                        <p class="popup-calendar-end-date-title">
                          วันที่สิ้นสุด
                        </p>
                        <v-text-field
                          :value="formatEndDate"
                          outlined
                          readonly
                          v-bind="attrsEndDate"
                          v-on="on"
                          dense
                        >
                          <template v-slot:append>
                            <v-fade-transition leave-absolute>
                              <img
                                width="24"
                                height="24"
                                src="../assets/icons/calendar.svg"
                                alt=""
                              />
                            </v-fade-transition>
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        id="date-specify--custom"
                        v-model="endDate"
                        no-title
                        scrollable
                        locale="th-TH"
                        color="primary"
                        :allowed-dates="getAllowedEndDates"
                      >
                        <v-spacer></v-spacer>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="2"
                    class="popup-calendar-btn-confirm-date-section"
                  >
                    <v-btn
                      :disabled="
                        availableAreaArea({ roomID, startDate, endDate }) ===
                          false
                      "
                      rounded
                      color="primary"
                      elevation="0"
                      @click="submitDateCriteria"
                      class="popup-calendar-btn-confirm-date"
                    >
                      <span class="popup-calendar-btn-confirm-date-text"
                        >ยืนยันวันที่</span
                      >
                    </v-btn>
                  </v-col>
                </v-row>
                <div
                  v-if="
                    availableAreaArea({ roomID, startDate, endDate }) === false
                  "
                >
                  <p class="mb-0 red--text">
                    *วันที่ท่านเลือกไว้ ได้มีการพิจารณาอนุมัติแล้ว
                    โปรดเลือกวันที่ว่าง โดยสามารถตรวจสอบที่ปฏิทินด้านล่าง
                  </p>
                </div>
                <v-divider
                  v-if="isShowDatePicker"
                  class="popup-calendar--custom"
                ></v-divider>
                <v-row class="popup-calendar-are-select-month">
                  <v-col
                    v-if="requestType === 'AREA' && !isShowDatePicker"
                    cols="12"
                    sm="4"
                    md="4"
                    class="popup-calendar-area-title"
                  >
                    <p class="mb-0 popup-calendar-title">
                      {{ roomName }}
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pl-4 d-flex flex-row justify-center"
                    v-if="requestType === 'AREA' && isShowDatePicker"
                    style="margin-top: 15px; margin-bottom: 15px;"
                  >
                    <div class="d-flex flex-row">
                      <v-btn
                        text
                        small
                        color="grey darken-2"
                        @click="prev"
                        outlined
                        min-width="35"
                        max-width="35"
                      >
                        <v-icon small>
                          mdi-chevron-left
                        </v-icon>
                      </v-btn>
                      <v-toolbar-title class="popup-calendar-area-select-month">
                        <p
                          class="mb-0 popup--calendar--text--title popup-calendar-title"
                        >
                          {{ momentFormatDate() }}
                        </p>
                      </v-toolbar-title>
                      <v-btn
                        text
                        small
                        color="grey darken-2"
                        @click="next"
                        :disabled="disableButtonNext"
                        outlined
                        min-width="35"
                        max-width="35"
                      >
                        <v-icon small>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    md="8"
                    class="pl-4"
                    v-if="requestType === 'AREA' && !isShowDatePicker"
                  >
                    <div class="d-flex flex-row">
                      <v-btn
                        text
                        small
                        color="grey darken-2"
                        @click="prev"
                        outlined
                        min-width="35"
                        max-width="35"
                      >
                        <v-icon small>
                          mdi-chevron-left
                        </v-icon>
                      </v-btn>
                      <v-toolbar-title
                        class="pl-3 pr-3 d-flex justify-center flex-column"
                      >
                        <p
                          class="mb-0 popup--calendar--text--title popup-calendar-title"
                        >
                          {{ momentFormatDate() }}
                        </p>
                      </v-toolbar-title>
                      <v-btn
                        text
                        small
                        color="grey darken-2"
                        @click="next"
                        :disabled="disableButtonNext"
                        outlined
                        min-width="35"
                        max-width="35"
                      >
                        <v-icon small>
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-if="requestType === 'AREA' && !isShowDatePicker">
                <v-col>
                  <v-row>
                    <v-col
                      v-if="requestType === 'AREA' && !isShowDatePicker"
                      cols="12"
                      sm="4"
                      md="4"
                      class="d-flex justify-center flex-column"
                    >
                      <p class="mb-0 popup-calendar-title">
                        {{ roomName }}
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      class="pl-4 d-flex flex-row justify-center"
                      v-if="requestType === 'AREA' && isShowDatePicker"
                      style="margin-top: 25px; margin-bottom: 15px;"
                    >
                      <div class="d-flex flex-row">
                        <v-btn
                          text
                          small
                          color="grey darken-2"
                          @click="prev"
                          outlined
                          min-width="35"
                          max-width="35"
                        >
                          <v-icon small>
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                        <v-toolbar-title
                          class="pl-3 pr-3 d-flex justify-center flex-column"
                        >
                          <p class="mb-0 popup--calendar--text--title">
                            {{ momentFormatDate() }}
                          </p>
                        </v-toolbar-title>
                        <v-btn
                          text
                          small
                          color="grey darken-2"
                          @click="next"
                          :disabled="disableButtonNext"
                          outlined
                          min-width="35"
                          max-width="35"
                        >
                          <v-icon small>
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="8"
                      md="8"
                      class="pl-4"
                      v-if="requestType === 'AREA' && !isShowDatePicker"
                    >
                      <div class="d-flex flex-row">
                        <v-btn
                          text
                          small
                          color="grey darken-2"
                          @click="prev"
                          outlined
                          min-width="35"
                          max-width="35"
                        >
                          <v-icon small>
                            mdi-chevron-left
                          </v-icon>
                        </v-btn>
                        <v-toolbar-title
                          class="popup-calendar-area-select-month"
                        >
                          <p
                            class="mb-0 popup--calendar--text--title popup-calendar-title"
                          >
                            {{ momentFormatDate() }}
                          </p>
                        </v-toolbar-title>
                        <v-btn
                          text
                          small
                          color="grey darken-2"
                          @click="next"
                          :disabled="disableButtonNext"
                          outlined
                          min-width="35"
                          max-width="35"
                        >
                          <v-icon small>
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="dialog = false"
                class="popup--calendar--closeicon"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-container>

            <v-sheet>
              <div>
                <full-calendar
                  id="full-calendar-header-custom"
                  :config="config"
                  :events="getDisplayEvent($vuetify.breakpoint.xsOnly)"
                  :header="false"
                  ref="fullCalendar"
                >
                  <template v-slot:dayHeaderContent="arg">
                    <p>{{ arg }}</p>
                  </template>
                </full-calendar>
              </div>
            </v-sheet>
            <br />
            <v-container class="popup-calendar-selection-container">
              <v-row class="popup-calendar-selection">
                <v-icon x-small color="primary"
                  >mdi-checkbox-blank-circle</v-icon
                >
                <p class="mb-0 pl-1 popup-calendar-selection-text">
                  รอดำเนินการ
                </p>
                <v-icon x-small class="pl-5" style="color:#E5E5E5;"
                  >mdi-checkbox-blank-circle</v-icon
                >
                <p class="mb-0 pl-1 popup-calendar-selection-text">
                  ไม่สามารถเลือกได้
                </p>
              </v-row>
            </v-container>
            <!-- Detail list -->
            <v-container
              class="hidden-md-and-up hidden-sm-and-up"
              v-if="getDisplayEvent($vuetify.breakpoint.xsOnly).length"
            >
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        style="
                            padding-left: 0px;
                        "
                      >
                        วันที่
                      </th>
                      <th
                        style="text-align: end;padding-left: 0px;padding-right: 0px;"
                      >
                        จำนวนผู้ขอใช้
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in getDisplayEvent(
                        $vuetify.breakpoint.xsOnly
                      )"
                      :key="item.name"
                    >
                      <td
                        style="padding-left: 0px;"
                        v-text="formatDate(item)"
                      ></td>
                      <td style="text-align: end;padding-left: 0px;">
                        {{ item.peopleUse }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// let currentDate = new Date(
//   Date.UTC(
//     new Date().getFullYear(),
//     new Date().getMonth(),
//     new Date().getDate()
//   )
// )
//   .toISOString()
//   .substr(0, 10);

import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";
import _get from "lodash/get";
export default {
  components: {},
  props: {
    isElevation: {
      type: Boolean,
      default: false
    },
    isShowDatePicker: {
      type: Boolean,
      default: false
    },
    roomName: {
      type: String,
      default: ""
    },
    roomID: {
      type: Number,
      default: null
    },
    // Start : Sasiprapa B. : 2021-05-05
    editArea: {
      type: Boolean,
      default: false
    }
    // End : Sasiprapa B. : 2021-05-05
  },
  mounted: function() {
    this.$nextTick(function() {
      // document.documentElement.style.overflow = "hidden";

      let getApplicantType = _get(
        this.$store.state.rentspacecriteria,
        "applicantType",
        null
      );

      let bookingDate;

      if(getApplicantType == 'INTERNAL') {
        bookingDate = moment().add(1, "days").format('YYYY-MM-DD');
        this.calendarTitle = moment().add(1, "days").format("MMMM YYYY");
        this.config.defaultDate = moment().add(1, "days").format('YYYY-MM-DD');
        this.addDate = 1;
      } else {
        bookingDate = moment().add(91, "days").format('YYYY-MM-DD');
        this.calendarTitle = moment().add(91, "days").format("MMMM YYYY");
        this.config.defaultDate = moment().add(91, "days").format("YYYY-MM-DD");
        this.addDate = 91;
      }
      
      this.startDate = bookingDate;
      this.endDate = bookingDate;

    });
  },
  watch: {
    endDate() {
      this.$refs.endDateDialog.save(this.endDate);
      if (this.endDate !== this.startDate) {
        this.oneDateCheckbox = false;
      }
      if (this.endDate === this.startDate) {
        this.oneDateCheckbox = true;
      }
    },
    startDate() {
      this.$refs.startDateDialog.save(this.startDate);
      if (this.startDate !== this.endDate) {
        this.oneDateCheckbox = false;
      }
      if (this.startDate > this.endDate) {
        this.endDate = this.startDate;
      }
      if (this.startDate === this.endDate) {
        this.oneDateCheckbox = true;
      }
    }
  },
  data() {
    let currentState = this;
    return {
      addDate: 91,
      calendarTitle: moment()
        .add(91, "days")
        .format("MMMM YYYY"),
      config: {
        selectable: false,
        editable: false,
        defaultView: "month",
        gotoDate: moment("2014-05-01"),
        columnFormat: "dd",
        defaultDate: moment()
          .add(91, "days")
          .format("YYYY-MM-DD"),
        dayRender: function(date, cell) {
          let keepState = [];
          if (currentState.unAvailableArea.length > 0) {
            for (const item of currentState.unAvailableArea) {
              let calendarDate = moment(date).format("YYYY-MM-DD");
              calendarDate = new Date(calendarDate).setHours(0, 0, 0, 0);
              let startDate = new Date(item.startDate).setHours(0, 0, 0, 0);
              let endDate = new Date(item.endDate).setHours(0, 0, 0, 0);

              if (
                calendarDate >= startDate &&
                calendarDate <= endDate &&
                item.roomId === currentState.roomID
              ) {
                keepState.push("inrange");
              }
            }

            if (keepState.indexOf("inrange") !== -1) {
              cell.css("background", "#E5E5E5");
            }
          }
        },
        views: {
          timeGridWeek: {
            slotMinTime: "09:00",
            slotMaxTime: "20:00",
            displayEventTime: false,
            dayHeaderContent(item) {
              return {
                html: `<h3>${item.date}</h3><p><label>${item.date}</label><span>${item.date}</span></p>`
              };
            }
          },
          timeGridDay: {
            dayHeaderContent(item) {
              return {
                html: `<h3>${item.text}</h3>`
              };
            }
          }
        }
      },
      dialog: false,
      startDate: moment().add(91, "days").format('YYYY-MM-DD'),
      endDate: moment().add(91, "days").format('YYYY-MM-DD'),
      // End : Sasiprapa Banyen : 2021-04-28
      startDateModal: false,
      endDateModal: false,
      oneDateCheckbox: true,
      disableButtonNext: false //Start : Sasiprapa B. : 2021-04-25
    };
  },
  computed: {
    formatStartDate() {
      let startDate = new Date(this.startDate);
      return new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      ).toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    },
    formatEndDate() {
      let endDate = new Date(this.endDate);
      return new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate()
      ).toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    },
    ...mapState({
      requestType: state => {
        return state.rentspacecriteria.requestType;
      },
      applicantType: state => {
        return state.rentspacecriteria.applicantType;
      }
    }),
    ...mapGetters("booking", {
      getDisplayEvent: "getDisplayEvent"
    }),
    ...mapGetters("areaspecify", {
      availableAreaArea: "getAvailableAreaForArea",
      unAvailableArea: "getUnAvailableArea"
    })
  },
  methods: {
    ...mapActions("datespecify", ["submitDate", "setOnSubmitDate"]),
    ...mapActions("booking", ["getBookingItemHistory"]),
    initPupupHistory() {
      // let getYear = moment().format("YYYY");
      // let getCurrentMonth = moment()
      //   .add(1, "month")
      //   .format("MM");
      // let getPrevMonth = moment().format("MM");
      // let getNextMonth = moment()
      //   .add(2, "month")
      //   .format("MM");
      // let getNextMonth2 = moment()
      //   .add(3, "month")
      //   .format("MM");
      // let getNextMonth3 = moment()
      //   .add(4, "month")
      //   .format("MM");

      const filters = {
        order: "id asc",
        where: {
          // or: [
          //   {
          //     startDate: {
          //       regexp: `${getYear}-${getCurrentMonth}.*`,
          //       options: "i"
          //     }
          //   },
          //   {
          //     startDate: {
          //       regexp: `${getYear}-${getPrevMonth}.*`,
          //       options: "i"
          //     }
          //   },
          //   {
          //     startDate: {
          //       regexp: `${getYear}-${getNextMonth}.*`,
          //       options: "i"
          //     }
          //   },
          //   {
          //     startDate: {
          //       regexp: `${getYear}-${getNextMonth2}.*`,
          //       options: "i"
          //     }
          //   },
          //   {
          //     startDate: {
          //       regexp: `${getYear}-${getNextMonth3}.*`,
          //       options: "i"
          //     }
          //   }
          // ],
          roomId: this.roomID
        },
        include: [
          {
            relation: "booking"
          }
        ]
      };
      this.getBookingItemHistory(filters);
    },
    momentFormatDate() {
      let formatDate = moment(this.calendarTitle, "MMMM YYYY")
        .add(543, "year")
        .locale("th-TH")
        .format("MMMM YYYY");
      return formatDate;
    },
    submitDateCriteria() {
      this.submitDate({
        startDate: this.startDate,
        endDate: this.endDate,
        isOneDaySelected: this.oneDateCheckbox
      });
      this.dialog = false;
      this.$emit("submit", this.editArea);
    },
    next() {
      this.calendarTitle = moment(this.calendarTitle, "MMMM YYYY")
        .add(1, "months")
        .format("MMMM YYYY");
      this.$refs.fullCalendar.fireMethod("next");
      // Start : Sasiprapa B. : 2021-04-25
      let maxDate = moment(new Date(), "YYYY-MM-DD")
        .add(1, "years")
        .startOf("day");
      let currentDate = moment(this.calendarTitle, "MMMM YYYY").startOf("day");

      if (
        currentDate.isSameOrAfter(maxDate, "years") &&
        currentDate.isSameOrAfter(maxDate, "months")
      ) {
        this.disableButtonNext = true;
      } else {
        this.disableButtonNext = false;
      }
      // End : Sasiprapa B. : 2021-04-25

      // let getYear = moment(this.calendarTitle, "MMMM YYYY").format("YYYY");
      // let getCurrentMonth = moment(this.calendarTitle, "MMMM YYYY")
      //   .add(1, "month")
      //   .format("MM");
      // let getPrevMonth = moment(this.calendarTitle, "MMMM YYYY").format("MM");
      // let getNextMonth = moment(this.calendarTitle, "MMMM YYYY")
      //   .add(2, "month")
      //   .format("MM");

      const filters = {
        order: "id asc",
        where: {
          // or: [
          //   {
          //     startDate: {
          //       regexp: `${getYear}-${getCurrentMonth}.*`,
          //       options: "i"
          //     }
          //   },
          //   {
          //     startDate: {
          //       regexp: `${getYear}-${getPrevMonth}.*`,
          //       options: "i"
          //     }
          //   },

          //   {
          //     startDate: {
          //       regexp: `${getYear}-${getNextMonth}.*`,
          //       options: "i"
          //     }
          //   }
          // ],
          roomId: this.roomID
        },
        include: [
          {
            relation: "booking"
          }
        ]
      };
      this.getBookingItemHistory(filters);
    },
    prev() {
      this.calendarTitle = moment(this.calendarTitle, "MMMM YYYY")
        .subtract(1, "months")
        .format("MMMM YYYY");
      this.$refs.fullCalendar.fireMethod("prev");

      // let getYear = moment(this.calendarTitle, "MMMM YYYY").format("YYYY");
      // let getCurrentMonth = moment(this.calendarTitle, "MMMM YYYY")
      //   .add(1, "month")
      //   .format("MM");
      // let getPrevMonth = moment(this.calendarTitle, "MMMM YYYY").format("MM");
      // let getNextMonth = moment(this.calendarTitle, "MMMM YYYY")
      //   .add(2, "month")
      //   .format("MM");

      const filters = {
        order: "id asc",
        where: {
          // or: [
          //   {
          //     startDate: {
          //       regexp: `${getYear}-${getCurrentMonth}.*`,
          //       options: "i"
          //     }
          //   },
          //   {
          //     startDate: {
          //       regexp: `${getYear}-${getPrevMonth}.*`,
          //       options: "i"
          //     }
          //   },
          //   {
          //     startDate: {
          //       regexp: `${getYear}-${getNextMonth}.*`,
          //       options: "i"
          //     }
          //   }
          // ],
          roomId: this.roomID
        },
        include: [
          {
            relation: "booking"
          }
        ]
      };
      this.getBookingItemHistory(filters);
    },
    toDay() {
      this.$refs.fullCalendar.fireMethod("today");
    },
    getHeight() {
      if (this.isShowDatePicker) {
        return 300;
      }
      return 80;
    },
    onOneDateChecked() {
      if (this.oneDateCheckbox) {
        // this.startDate = currentDate;
        this.endDate = this.startDate;
      }
      if (this.startDate === this.endDate) {
        this.oneDateCheckbox = true;
      }
    },
    // onSubmitStartDate() {
    //   this.$refs.startDateDialog.save(this.startDate);
    //   if (this.startDate !== this.endDate) {
    //     this.oneDateCheckbox = false;
    //   }
    //   if (this.startDate > this.endDate) {
    //     this.endDate = this.startDate;
    //   }
    //   if (this.startDate === this.endDate) {
    //     this.oneDateCheckbox = true;
    //   }
    // },
    // onSubmitEndDate() {
    //   this.$refs.endDateDialog.save(this.endDate);
    //   if (this.endDate !== this.startDate) {
    //     this.oneDateCheckbox = false;
    //   }
    //   if (this.endDate === this.startDate) {
    //     this.oneDateCheckbox = true;
    //   }
    // },
    getAllowedStartDates(val) {
      return moment(val).isSameOrAfter(moment().add(this.addDate, "days"), "date"); //Start : Sasiprapa Banyen : 2021-04-29
      // return moment(val).isSameOrAfter(moment(), "date");
    },
    getAllowedEndDates(val) {
      return moment(val).isSameOrAfter(moment(this.startDate), "date");
    },
    // Start : Sasiprapa B. : 2021-05-04
    formatDate(param) {
      let getStartDate = _get(param, "start", "");
      let getEndDate = _get(param, "end", "");
      if (getStartDate && getEndDate) {
        let startDate = new Date(getStartDate);
        let endDate = new Date(getEndDate);
        let startDateFormat = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        ).toLocaleDateString("th-TH", {
          month: "long",
          day: "numeric"
        });
        let endDateFormat = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate()
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
        return `${startDateFormat} - ${endDateFormat}`;
      }
    }
    // End : Sasiprapa B. : 2021-05-04
  }
};
</script>

<style scoped>
.popup-calendar-card {
  border-radius: 10px;
}

.popup-calendar-title {
  font-weight: 700;
  font-size: 21px;
  margin-bottom: 0px;
}

.popup-calendar-selection {
  display: flex;
  justify-content: center;
  /* pa-3 */
  padding: 13px;
  /* mt-4 */
  margin-top: 16px;
}

.popup-calendar-selection-text {
  font-weight: 400;
  font-size: 14px;
}

.popup--calendar--closeicon {
  position: absolute;
  top: 0px;
  right: 0px;
}

#ggg-fff > .v-calendar-weekly__head {
  background-color: black !important;
  color: white !important;
}

#custom--stype--calendar
  >>> .v-calendar-weekly__head
  > .v-calendar-weekly__head-weekday {
  background: black !important;
  height: 30px !important;
  color: white !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  border-right: 1px solid #1e1e1e !important;
  border-left: 1px solid #1e1e1e !important;
}

.test--sdf {
  color: red !important;
  background: aquamarine !important;
  background-color: salmon !important;
}

#full-calendar-header-custom >>> .fc-head-container > .fc-widget-header {
  background: black !important;
  height: 45px !important;
  color: white !important;
}

#full-calendar-header-custom >>> .fc-head-container > .fc-widget-header th {
  height: 45px !important;
  padding-top: 10px;
}

.popup--calendar--text--title {
  max-width: 160px;
  min-width: 160px;
  text-align: center;
}

.popup-calendar-content {
  justify-content: flex-end;
}

.popup-calendar-space-request {
  max-width: 117px;
  height: 21px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #282828;
  text-decoration: underline;
  letter-spacing: 0;
}
.popup-calendar-edit-btn {
  max-width: 29px;
  height: 21px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
  color: #282828;
  letter-spacing: 0;
}

/* Button */
.popup-calendar-select-are-request-btn {
  max-width: 198px !important;
  height: 50px !important;
  border-radius: 25px;
  padding: 15px 57px 14px 55px !important;
}

.popup-calendar-select-are-request-btn-text {
  max-width: 86px;
  height: 21px;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #282828;
  margin-bottom: 0px;
}

.popup-calendar-start-date-title {
  width: 63px;
  height: 21px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 21px;
  text-align: left;
  color: #282828;
  margin-bottom: 7px;
}

.popup-calendar-title-container {
  padding-top: 12px;
  padding-bottom: 12px;
}

.popup-calendar-start-date-container {
  padding-top: 12px;
  padding-bottom: 12px;
}

/* Button */
.popup-calendar-btn-confirm-date-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0px;
}

.popup-calendar-btn-confirm-date {
  max-width: 162px !important;
  height: 50px !important;
  border-radius: 25px;
  padding: 15px 52px 14px 52px !important;
}

.popup-calendar-btn-confirm-date-text {
  width: 58px;
  height: 21px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #282828;
}

.popup-calendar-end-date-title {
  width: 61px;
  height: 21px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 21px;
  text-align: left;
  color: #282828;
  margin-bottom: 7px;
}

.popup-calendar-day {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popup-calendar-end-date-container {
  padding-top: 12px;
  padding-bottom: 12px;
}

.popup-calendar-end-date-menu {
  margin-top: 0px;
}

.popup-calendar-are-container {
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.popup-calendar-are-select-month {
  margin-top: -12px;
}

.popup-calendar-selection-container {
  /* mt-5 */
  margin-top: -25px;
}

.popup-calendar-space-request-room-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0px;
}

.popup-calendar-space-request-select-month {
  padding-left: 16px;
}

/* Space Request */
.popup-calendar-space-request-container {
  height: 64px;
}

.popup-calendar-space-request-select-container {
  display: flex;
  flex-direction: row;
  padding-left: 10%;
}

.popup-calendar-space-request-select-container-title {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popup-calendar-area-select-month {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* AREA Section*/
.popup-calendar-area-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0px;
}

.popup-calendar-area-select-month {
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* @media (max-width: 480px) {
  .popup-calendar--custom {
    display: none;
  }
}


@media (min-width: 481px) {
} */
/* Mobile */
@media (max-width: 959px) {
  .popup-calendar-btn-confirm-date {
    max-width: 192px !important;
    min-width: 192px !important;
    height: 45px !important;
    border-radius: 25px;
    padding: 11px 62px 10px 63px;
  }

  .popup-calendar-btn-confirm-date-text {
    width: 58px;
    height: 21px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    color: #282828;
  }

  .popup-calendar-btn-confirm-date-section {
    flex-direction: row;
    margin-top: 26px;
    padding-top: 0px;
  }

  .popup-calendar--custom {
    margin-top: 34px;
  }

  .popup-calendar-day {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .popup-calendar-are-select-month {
    margin-top: 0px;
  }

  /* End Date */
  .popup-calendar-end-date-container {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 22px;
  }

  .popup-calendar-start-date-container {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 23px;
  }

  .popup-calendar-title-container {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 23px;
  }

  .popup-calendar-selection {
    padding: 0px;
    margin-top: 0px;
  }

  .popup-calendar-selection-container {
    margin-top: 0px;
    padding-top: 0px;
  }
}

@media (max-width: 559px) {
  /* Space Request */
  .popup-calendar-space-request-container {
    height: 130px !important;
  }

  .popup-calendar-space-request-section {
    margin-top: 0px;
    margin-bottom: -70px;
  }

  .popup-calendar-space-request-room-name {
    padding-bottom: 0px;
    padding-right: 0px;
    padding-top: 0px;
  }

  .popup-calendar-title {
    margin-bottom: 41px;
  }

  .popup-calendar-space-request-select-container {
    display: flex;
    flex-direction: row;
    padding-left: 0px;
  }

  .popup-calendar-space-request-select-container-title {
    width: 100% !important;
    align-items: center !important;
  }

  .popup-calendar-space-request-select-month {
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
  }

  /* AREA Section */
  .popup-calendar-area-select-month {
    align-items: center;
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
  .popup-calendar-area-title {
    padding-left: 0px;
    padding-top: 23px;
  }
}
</style>
