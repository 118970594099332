<template>
  <v-container>
    <v-card class="mx-auto government--info--card">
      <v-container class="government--info--container">
        <p class="text--bold--18 government--form--label">
          ประเภทผู้ขอใช้ : หน่วยงานรัฐ/ราชการ
        </p>
        <v-divider class="mb-2 mt-2"></v-divider>
        <v-form ref="form" v-model="valid" lazy-validation>
          <p class="mb-0 text--bold--14 government--form--label">
            ประเภทหน่วยงานรัฐ/ราชการ<span class="red--text">*</span>
          </p>
          <v-select
            :items="orgItemList"
            dense
            outlined
            item-text="organization_name"
            return-object
            :rules="[v => !!v || 'กรุณาเลือก ประเภทหน่วยงานรัฐ/ราชการ']"
            v-model="form.agencyType"
            @change="setOrganizationsTypeRequireDoc"
          ></v-select>

          <p class="mb-0 text--bold--14 government--form--label">
            ชื่อองค์กร/บริษัท (ภาษาไทย)<span class="red--text">*</span>
          </p>
          <v-text-field
            class="text--regular--placeholder--14"
            v-model="form.thOrgName"
            :rules="thOrgNameRule"
            placeholder="ชื่อองค์กร/บริษัท (ภาษาไทย)"
            required
            single-line
            outlined
            dense
          ></v-text-field>

          <p class="mb-0 text--bold--14 government--form--label">
            ชื่อองค์กร/บริษัท (ภาษาอังกฤษ)<span class="red--text">*</span>
          </p>
          <v-text-field
            class="text--regular--placeholder--14"
            v-model="form.enOrgName"
            :rules="enOrgNameRule"
            placeholder="ชื่อองค์กร/บริษัท (ภาษาอังกฤษ)"
            required
            single-line
            outlined
            dense
          ></v-text-field>

          <p class="mb-0 text--bold--14 government--form--label">
            ชื่อ - นามสกุล (ภาษาไทย)<span class="red--text">*</span>
          </p>
          <v-text-field
            class="text--regular--placeholder--14"
            v-model="form.thname"
            :rules="thaiNameRule"
            placeholder="ชื่อ - นามสกุล"
            required
            single-line
            outlined
            dense
          ></v-text-field>

          <p class="mb-0 text--bold--14 government--form--label">
            ชื่อ - นามสกุล (ภาษาอังกฤษ)<span class="red--text">*</span>
          </p>
          <v-text-field
            class="text--regular--placeholder--14"
            v-model="form.enname"
            :rules="engNameRule"
            placeholder="Full Name"
            required
            single-line
            outlined
            dense
          ></v-text-field>

          <p class="mt-2 mb-2 text--bold--18 government--form--label">
            ที่อยู่
          </p>
          <v-divider class="pt-5"></v-divider>

          <FormAddress ref="formAddress" />

          <v-divider class="pt-5"></v-divider>

          <p class="mb-0 text--bold--14 government--form--label">
            หัวข้อการจัดกิจกรรม<span class="red--text">*</span>
          </p>
          <v-text-field
            class="text--regular--placeholder--14"
            v-model="form.activities"
            :rules="[v => !!v || 'กรุณาระบุ หัวข้อการจัดกิจกรรม']"
            placeholder="หัวข้อการจัดกิจกรรม"
            required
            single-line
            outlined
            dense
          ></v-text-field>

          <v-divider class="pt-5"></v-divider>

          <p class="text--bold--14 mb-0 government--form--label">
            รูปภาพตัวอย่างการจัดกิจกรรม<span class="red--text">*</span>
          </p>
          <span
            style="color: #b71c1c; font-size: 12px !important;"
            v-if="isSubmit && form.activityImageList.length === 0"
            class="text--regular--placeholder--14"
          >
            กรุณาอัพโหลด รูปภาพตัวอย่างการจัดกิจกรรม
          </span>
          <div class="mt-3">
            <v-container>
              <UploadActivityImags
                @onUpload="onUpload"
                @onRemoveFile="onRemoveFile"
              />
            </v-container>
          </div>

          <!-- <p class="mb-0 text--regular--14">
            • โปรดแนบรูปภาพที่จะใช้เป็นตัวอย่างในการจัดกิจกรรม อย่างน้อย 30%
            ของการจัดงานทั้งหมด
          </p>
          <p class="text--regular--14 mb-0">
            • ไฟล์ที่แนะนำ Jpg, Png (แนะนำไม่เกินรูปละ 5 mb)
          </p> -->
          <p class="text--bold--14 mb-2 private--form--label">
            กรณีจัดนิทรรศการ
          </p>
          <p class="mb-0 text--regular--14 ">
            • โปรดแนบรูปภาพที่จะใช้เป็นตัวอย่างในการจัดกิจกรรม ไม่น้อย<br />
            กว่า 30% ของผลงานที่จะแสดงทั้งหมด <br />
            และ หรือ ภาพกิจกรรมหรือผลงานที่จะจัดแสดง
          </p>
          <p class="text--regular--14 mb-3">
            • ไฟล์ที่แนะนำ Jpg, Png (แนะนำไม่เกินรูปละ 5 mb)
          </p>
          <p class="text--bold--14 mb-2 private--form--label">
            กรณีจัดแถลงข่าว/ประชุม/งานประเภทอื่น ๆ :
          </p>
          <p class="mb-0 text--regular--14 ">
            • โปรดอัพโหลดภาพตัวอย่างกิจกรรม หรือรูปแบบการจัดงาน
          </p>
          <p class="text--regular--14">
            • ไฟล์ที่แนะนำ Jpg, Png (แนะนำไม่เกินรูปละ 5 mb)
          </p>
        </v-form>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _get from "lodash/get";

import UploadActivityImags from "./UploadActivityImags";
import FormAddress from "./FormAddress";

export default {
  components: {
    UploadActivityImags,
    FormAddress
  },
  data: () => ({
    isSubmit: false,
    reveal: false,
    valid: true,
    name: "",
    form: {
      zipcode: "",
      thname: "",
      enname: "",
      address: "",
      subdistrict: "",
      province: "",
      district: "",
      mobile: "",
      activities: "",
      activityImageList: [],
      thOrgName: "",
      enOrgName: "",
      agencyType: ""
    },
    emailRules: [
      v => !!v || "กรุณา ระบุอีเมล",
      v => /.+@.+\..+/.test(v) || "กรุณาระบุ รหัสผ่านให้ถูกต้อง"
    ],
    engNameRule: [
      v => {
        if (v) {
          return (
            /^[0-9a-z ,.'-]+$/i.test(v) ||
            "กรุณาระบุ ชื่อ - นามสกุล (ภาษาอังกฤษ) ให้ถูกต้อง"
          );
        } else {
          return false || "กรุณาระบุ ชื่อ - นามสกุล (ภาษาอังกฤษ) ให้ถูกต้อง";
        }
      }
    ],
    thaiNameRule: [
      v => {
        if (v) {
          return (
            /^[0-9ก-๏\s]+$/i.test(v) ||
            "กรุณาระบุ ชื่อ - นามสกุล (ภาษาไทย) ให้ถูกต้อง"
          );
        } else {
          return false || "กรุณาระบุ ชื่อ - นามสกุล (ภาษาไทย) ให้ถูกต้อง";
        }
      }
    ],
    enOrgNameRule: [
      v => {
        if (v) {
          return (
            /^[0-9a-z ,.'-]+$/i.test(v) ||
            "กรุณาระบุ ชื่อองค์กร/บริษัท (ภาษาอังกฤษ) ให้ถูกต้อง"
          );
        } else {
          return false || "กรุณาระบุ ชื่อองค์กร/บริษัท (ภาษาอังกฤษ) ให้ถูกต้อง";
        }
      }
    ],
    thOrgNameRule: [
      v => {
        if (v) {
          return (
            /^[0-9ก-๏\s]+$/i.test(v) ||
            "กรุณาระบุ ชื่อองค์กร/บริษัท (ภาษาไทย) ให้ถูกต้อง"
          );
        } else {
          return false || "กรุณาระบุ ชื่อองค์กร/บริษัท (ภาษาไทย) ให้ถูกต้อง";
        }
      }
    ]
  }),
  computed: mapState({
    orgItemList: state => {
      let orgList = _get(
        state,
        "rentspacecriteria.applicantSelected.organizationsTypes",
        ""
      );
      if (orgList) {
        return orgList;
      } else {
        return [];
      }
    }
  }),
  methods: {
    ...mapActions("applicantdetail", ["setOrganizationsType", "setRequireDoc"]),
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    setOrganizationsTypeRequireDoc(param) {
      let orgId = _get(param, "id", "");
      this.form.agencyType = _get(param, "organization_name", "");
      let organizationsTypes = _get(
        this.$store.state,
        "rentspacecriteria.applicantSelected.organizationsTypes",
        []
      );
      if (organizationsTypes.length > 0) {
        let filtersRequireDocs = organizationsTypes.filter(o => o.id === orgId);
        if (filtersRequireDocs.length > 0) {
          let getRequireDocs = _get(
            filtersRequireDocs,
            "[0].requireDocs",
            null
          );
          this.setRequireDoc(getRequireDocs);
        }
      }
      this.setOrganizationsType(orgId);
    },
    onUpload(fileName) {
      if (fileName !== null) {
        this.form.activityImageList = this.form.activityImageList.concat(
          fileName
        );
      }
    },
    onRemoveFile(index) {
      if (index !== null && index !== undefined) {
        this.form.activityImageList.splice(index, 1);
      }
    },
    onSubmitForm() {
      this.isSubmit = true;
      let formAddress = this.$refs.formAddress.$refs.formAddress.validate();
      let validate = this.$refs.form.validate();
      if (formAddress) {
        this.form = { ...this.form, ...this.$refs.formAddress.formAddress };
      }
      return validate && formAddress && this.form.activityImageList.length > 0;
    }
  }
};
</script>

<style scoped>
.guestinfo--upload--container {
  position: relative;
  width: 110px;
  height: 110px;
  border: 2px dashed #d1d1d1;
}
.guestinfo--upload--icon--plus--container {
  position: absolute;
  top: 40%;
  left: 40%;
}

.government--form--label {
  margin-bottom: 10px !important;
}

@media (max-width: 480px) {
  .government--info--card {
    box-shadow: 0px 0px 15px #496c7733 !important;
    border-radius: 10px !important;
  }
  .government--info--container {
    padding: 27px 17px 45px 18px !important;
  }
  .private--info--title {
    margin-bottom: 10px;
  }
}

@media (min-width: 481px) {
  .government--info--card {
    box-shadow: 0px 0px 15px #496c7733 !important;
    border-radius: 10px !important;
  }
  .private--info--title {
    margin-bottom: 19px;
  }
  .government--info--container {
    padding: 30px !important;
  }
}
</style>
