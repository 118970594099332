<template>
  <v-container>
    <v-row cols="12" sm="4" md="4" class="text-center">
      <v-col class="pt-0">
        <p class="text--bold--20 mb-0 summary--title">
          ส่งคำขอการใช้พื้นที่สำเร็จ
        </p>
        <p class="mb-1 summary-header-title">
          {{ bookingNo }}
        </p>
        <div
          class="hidden-sm-and-up hidden-md-and-up hidden-sm-and-up hidden-xl-and-up"
        >
          <p class="summary-header-email">
            หากท่านไม่ได้รับอีมลยืนยัน สามารถกดที่ “รับอีเมลยืนยัน <br />
            อีกครั้ง” ที่ปุ่มด้านล่างนี้และระบบจะทำการส่งอีเมลให้ท่าน <br />
            อีกครั้งนึง <a @click="onResendMail">รับอีเมลอีกครั้ง</a>
          </p>
        </div>

        <div class="hidden-xs-only">
          <p class="mb-0 summary-header-email">
            หากท่านไม่ได้รับอีมลยืนยัน สามารถกดที่ “รับอีเมลยืนยัน อีกครั้ง”
            ที่ปุ่มด้านล่างนี้
          </p>
          <p class="summary-header-email-subtitle">
            และระบบจะทำการส่งอีเมลให้ท่าน อีกครั้งนึง
            <a @click="onResendMail">รับอีเมลอีกครั้ง</a>
          </p>
        </div>
        <v-row
          cols="12"
          sm="4"
          md="4"
          class="text-center d-flex justify-center"
        >
          <v-btn
            style="margin: 16px;"
            rounded
            outlined
            elevation="0"
            class="reservations-history-btn summary-button"
            @click="onSubmitSummary"
          >
            ดูประวัติการจองพื้นที่
          </v-btn>
          <v-btn
            style="margin: 16px;"
            rounded
            color="primary"
            dark
            elevation="0"
            class="black--text summary-button"
            @click="onExportPdfFile"
          >
            ดาวน์โหลดแบบขอใช้
          </v-btn>
        </v-row>
        <div
          class="hidden-sm-and-up hidden-md-and-up hidden-sm-and-up hidden-xl-and-up"
        >
          <p class="p-header-tel text-center">
            หากมีคำถามในการจองพื้นที่อื่นๆ เพิ่มเติม <br />
            สามารถติดต่อได้ที่ 0 2224 8030 ต่อ 202
          </p>
        </div>
        <div class="hidden-xs-only">
          <p class="p-header-tel">
            หากมีคำถามในการจองพื้นที่อื่นๆ เพิ่มเติม สามารถติดต่อได้ที่ 0 2224
            8030 ต่อ 202
          </p>
        </div>
      </v-col>
    </v-row>
    <br />
    <v-card class="mx-auto summary-card" outlined v-if="
                  bookingItem.bookingData.applicantType === 'GUEST' || bookingItem.bookingData.applicantType === 'GOVERNMENTAGENCY' || bookingItem.bookingData.applicantType === 'PRIVATEAGENCY'
                ">
      <v-container
        v-if="bookingItemResult !== null && bookingItemResult.length > 0"
        class="pa-0"
      >
        <v-row dense no-gutters>
          <v-col
            v-for="item in bookingItemResult"
            :key="`${item.id}${item.startDate}${item.endDate}`"
            cols="12"
          >
            <v-container
              style="
                    border-radius: 10px !important;
                      border: none !important;
                      background-color: #ffffff !important;
                "
            >
              <v-row class="d-flex flex-no-wrap card-padding">
                <v-col cols="6" sm="6" md="4" class="text-center">
                  <v-container class="pt-5" style="cursor: pointer;">
                    <img :src="getFileURL(item.imageName)" />
                  </v-container>
                </v-col>
                <v-col cols="6" sm="6" md="5">
                  <p
                    class="card-title-font pt-0 mb-0"
                    v-text="item.name + ' - ชั้น ' + item.floor"
                  ></p>
                  <p
                    class="text--regular--14 pt-0 summary--room--desc"
                    v-text="item.description"
                  ></p>
                  <p class="card-title-font pt-0 mb-0">
                    วันที่ขอใช้พื้นที่
                  </p>
                  <p
                    class="text--regular--14 pt-0 summary--room--date"
                    v-text="formatDate(item)"
                  ></p>
                  <p class="card-title-font pt-0 mb-0 ">
                    จำนวน (วัน)
                  </p>
                  <p
                    class="text--regular--14 pt-0 summary--room--date"
                    v-text="getDateDiff(item) + ' วัน (ตามเวลาทำการ)'"
                  ></p>
                  <p class="card-title-font pt-0 mb-0">
                    ราคาทั้งหมด
                  </p>
                  <!-- <p
                  class="text--regular--14 summary--room--price"
                  v-text="item.price.toLocaleString() + ' บาท'"
                ></p> -->
                  <p class="text--regular--14 summary--room--price">
                    {{ areaGetPricePerRoom(item).toLocaleString() }} บาท
                  </p>
                </v-col>
                <div v-if="item.objective !== null">
                  <v-col
                    cols="12"
                    sm="12"
                    md="3"
                    v-if="
                      item.objective.indexOf('ใช้เป็นห้องจัดแถลงข่าว') !== -1
                    "
                  >
                    <div class="summary-conference-button">
                      <v-btn
                        rounded
                        style="background-color: #FDF7DF !important;"
                        elevation="0"
                        disabled
                      >
                        <span class="primary--text"
                          >ใช้เป็นห้องจัดแถลงข่าว</span
                        >
                      </v-btn>
                    </div>
                  </v-col>
                </div>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-if="
                    bookingItemResult.indexOf(item) !==
                      bookingItemResult.length - 1
                  "
                >
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <div style="background-color: var(--v-primary); height: 8px;"></div>
        <v-row no-gutters class="sumary-footer-bg sumary-footer-border">
          <v-col
            cols="12"
            sm="8"
            offset-sm="2"
            md="8"
            offset-md="2"
            lg="8"
            offset-lg="2"
          >
            <!-- <v-card class="grey darken-3 lighten-1 white--text"> -->
            <v-card
              class="summary--bottom--container sumary-footer-bg lighten-1 white--text"
              style="box-shadow: none !important;"
            >
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6" sm="6" md="6" class="text-start pb-0">
                        <!-- <p class="text--regular--16 mb-0">ค่าประกันสัญญา</p> -->
                        <div
                          class="tooltip primary--text summary-price mb-1"
                          style="cursor: pointer;"
                        >
                          <p
                            class="text--regular--16 mb-0"
                            style="color: #FFFFFF;"
                          >
                            ค่าประกันสัญญา
                            <v-icon class="primary--text"
                              >mdi-help-circle-outline</v-icon
                            >
                          </p>

                          <span class="tooltiptext"
                            >เพิ่ม 10,000 บาทในทุกๆ 7 วัน</span
                          >
                        </div>
                      </v-col>
                      <v-col cols="6" sm="6" md="6" class="text-end pb-0">
                        <p class="summary-price mb-1">
                          {{ areaCartDepositCalculate.toLocaleString() }} บาท
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="pt-0 pb-0">
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="text-start pb-0"
                        style="padding-top: 5px;"
                      >
                        <p class="text--regular--16 mb-1">
                          ค่าเงินบำรุงรักษา (อัตราค่าเช่า)
                        </p>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="text-end pb-0"
                        style="padding-top: 5px;"
                      >
                        <p class="summary-price mb-1">
                          {{
                            areaCartMaintenanceCostCalculate.toLocaleString()
                          }}
                          บาท
                        </p>
                      </v-col>
                    </v-row>
                    <v-divider class="grey sumary-divider-padding"></v-divider>
                    <v-row>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="text-start"
                        style="padding-top: 10px;"
                      >
                        <p class="summary-total">ราคาทั้งหมด</p>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        md="6"
                        class="text-end"
                        style="padding-top: 10px;"
                      >
                        <p class="summary-total">
                          {{ areaCartSummaryPriceCalculate.toLocaleString() }}
                          บาท
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card class="mx-auto summary-card col-8" outlined v-if="
                  bookingItem.bookingData.applicantType === 'INTERNAL'
                ">
      <v-col cols="12" sm="12" md="12">
        <v-container
          v-if="bookingItemResult !== null && bookingItemResult.length > 0"
          class="applicaion--detail--custom pa-10"
        >
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="text--bold--18 government--form--label">
                รายละเอียดผู้ขอใช้พื้นที่
              </p>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <p class="mb-0 text--bold--14 government--form--label">
                หน่วยงาน
              </p>
              {{bookingItem.bookingData.department_name}}
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <p class="mb-0 text--bold--14 government--form--label">
                กลุ่มงาน
              </p>
              {{bookingItem.bookingData.group_name}}
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <p class="mb-0 text--bold--14 guestinfo--form--label">
                เจ้าหน้าที่รับผิดชอบ
              </p>
              {{bookingItem.bookingData.thname}}
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <p class="mb-0 text--bold--14 formaddress--label">
                หมายเลขโทรศัพท์ภายใน
              </p>
              {{bookingItem.bookingData.tel}}
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <p class="mb-0 text--bold--14 formaddress--label">
                หมายเลขโทรศัพท์เคลื่อนที่
              </p>
              {{bookingItem.bookingData.mobile}}
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="mb-0 text--bold--14 government--form--label">
                ชื่องาน
              </p>
              {{bookingItem.bookingData.activities}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pre-formatted">
              <p class="mb-0 text--bold--14 government--form--label">
                ประเภทของงาน / รายละเอียด
              </p>
              {{bookingItem.bookingData.activities_detail}}
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>
          <v-row dense no-gutters>
            <v-col
              v-for="item in bookingItemResult"
              :key="`${item.id}${item.startDate}${item.endDate}`"
              cols="12"
            >
              <v-container
                style="
                      border-radius: 10px !important;
                        border: none !important;
                        background-color: #ffffff !important;
                  "
              >
                <v-row class="d-flex flex-no-wrap card-padding">
                  <v-col cols="6" sm="6" md="4" class="text-center">
                    <v-container class="pt-5" style="cursor: pointer;">
                      <img :src="getFileURL(item.imageName)" />
                    </v-container>
                  </v-col>
                  <v-col cols="6" sm="6" md="5">
                    <p
                      class="card-title-font pt-0 mb-0"
                      v-text="item.name + ' - ชั้น ' + item.floor"
                    ></p>
                    <p
                      class="text--regular--14 pt-0 summary--room--desc"
                      v-text="item.description"
                    ></p>
                    <p class="card-title-font pt-0 mb-0">
                      วันที่ขอใช้พื้นที่
                    </p>
                    <p
                      class="text--regular--14 pt-0 summary--room--date"
                      v-text="formatDate(item)"
                    ></p>
                    <p class="card-title-font pt-0 mb-0 ">
                      จำนวน (วัน)
                    </p>
                    <p
                      class="text--regular--14 pt-0 summary--room--date"
                      v-text="getDateDiff(item) + ' วัน (ตามเวลาทำการ)'"
                    ></p>
                  </v-col>
                  <div v-if="item.objective !== null">
                    <v-col
                      cols="12"
                      sm="12"
                      md="3"
                      v-if="
                        item.objective.indexOf('ใช้เป็นห้องจัดแถลงข่าว') !== -1
                      "
                    >
                      <div class="summary-conference-button">
                        <v-btn
                          rounded
                          style="background-color: #FDF7DF !important;"
                          elevation="0"
                          disabled
                        >
                          <span class="primary--text"
                            >ใช้เป็นห้องจัดแถลงข่าว</span
                          >
                        </v-btn>
                      </div>
                    </v-col>
                  </div>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    v-if="
                      bookingItemResult.indexOf(item) !==
                        bookingItemResult.length - 1
                    "
                  >
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0 text--bold--14 government--form--label">
                วันที่เริ่มติดตั้ง
              </p>
              {{formatDateDisplay(bookingItem.bookingData.setup_start_date)}}
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0 text--bold--14 government--form--label">
                เวลาเริ่มติดตั้ง
              </p>
              {{bookingItem.bookingData.setup_start_time}} น.
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0 text--bold--14 guestinfo--form--label">
                เวลาสิ้นสุดติดตั้ง
              </p>
              {{bookingItem.bookingData.setup_end_time}} น.
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0 text--bold--14 government--form--label">
                วันรื้อถอนสมบูรณ์
              </p>
              {{formatDateDisplay(bookingItem.bookingData.remove_start_date)}}
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0 text--bold--14 government--form--label">
                เวลาเริ่มรื้อถอน
              </p>
              {{bookingItem.bookingData.remove_start_time}} น.
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0 text--bold--14 guestinfo--form--label">
                เวลาสิ้นสุดรื้อถอน
              </p>
              {{bookingItem.bookingData.remove_end_time}} น.
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-if="bookingItemResult[0].startDate !== bookingItemResult[0].endDate">
              <p class="mb-0 text--bold--14 government--form--label">
                กำหนดการจัดงาน
              </p>
              {{formatDateDisplayShort(bookingItemResult[0].startDate)}} - {{formatDateDisplayShort(bookingItemResult[0].endDate)}}
            </v-col>
            <v-col cols="12" sm="6" md="4" v-if="bookingItemResult[0].startDate === bookingItemResult[0].endDate">
              <p class="mb-0 text--bold--14 government--form--label">
                กำหนดการจัดงาน
              </p>
              {{formatDateDisplayShort(bookingItemResult[0].startDate)}}
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0 text--bold--14 government--form--label">
                ระยะเวลาจัดงานตั้งแต่
              </p>
              เริ่ม {{bookingItem.bookingData.event_start_time}} น. ถึง {{bookingItem.bookingData.event_end_time}} น.
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0 text--bold--14 guestinfo--form--label">
                รวมระยะเวลาการจัดงานทั้งหมด
              </p>
              {{getDateDiff(bookingItemResult[0])}} วัน
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pre-formatted">
              <p class="mb-0 text--bold--14 government--form--label">
                อุปกรณ์ที่คาดว่าจะต้องใช้
              </p>
              {{bookingItem.bookingData.equipment}}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12" class="pre-formatted">
              <p class="mb-0 text--bold--14 government--form--label">
                จำนวนที่จอดรถยนต์ (คาดการณ์)
              </p>
              {{bookingItem.bookingData.parking}} คัน
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import _get from "lodash/get";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _flattenDeep from "lodash/flattenDeep";
import _uniqBy from "lodash/unionBy";

export default {
  mounted() {
    this.setBackgroundMain();
  },
  computed: {
    ...mapGetters("areaspecify", {
      areaCartSummaryPriceCalculate: "areaCartSummaryPriceCalculate",
      areaCartMaintenanceCostCalculate: "areaCartMaintenanceCostCalculate",
      areaCartDepositCalculate: "areaCartDepositCalculate",
      areaGetPricePerRoom: "areaGetPricePerRoom"
    }),
    ...mapState({
      bookingItemResult(state) {
        let bookingItemData = _get(state.booking, "bookingItemData", []);
        let getAreaRequestLists = _get(
          state.confirmation,
          "confirmMationItem.areaRequestLists",
          []
        );
        let resultResponse = [];
        if (getAreaRequestLists.length > 0) {
          let filterResult = _map(bookingItemData, bookItem => {
            let filterResult = _filter(getAreaRequestLists, areaRequest => {
              return areaRequest.id === bookItem.roomId;
            });
            return filterResult;
          });
          if (filterResult.length > 0) {
            resultResponse = resultResponse.concat(filterResult);
          }
        }
        let flattenDeep = _flattenDeep(resultResponse);
        let uniqBy = _uniqBy(
          flattenDeep,
          o => `${o.id}${o.startDate}${o.endDate}`
        );
        return uniqBy;
      },
      bookingNo(state) {
        if (state.booking.bookingHistory) {
          if (state.booking.bookingHistory.length > 0) {
            let getLength = state.booking.bookingHistory.length - 1;
            let bookingResult = _get(
              state.booking.bookingHistory,
              `${[getLength]}.bookingNo`,
              ""
            );
            return `เลขที่การขอใช้พิ้นที่ #${bookingResult}`;
          }
          return "";
        }
      },
      bookingItem(state) {
        return state.booking;
      }
    })
  },
  data: () => ({}),
  methods: {
    ...mapActions("app", ["setBackgroundFormStepperScreen"]),
    ...mapActions("sendmail", ["sendEMail"]),
    ...mapActions("exportpdf", ["exportFilePDF"]),
    onSubmitSummary() {
      this.$router.push("/history");
    },
    formatDate(param) {
      let getStartDate = _get(param, "startDate", "");
      let getEndDate = _get(param, "endDate", "");
      if (getStartDate && getEndDate) {
        let startDate = new Date(getStartDate);
        let endDate = new Date(getEndDate);
        let startDateFormat = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        ).toLocaleDateString("th-TH", {
          month: "long",
          day: "numeric"
        });
        let endDateFormat = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate()
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
        return `${startDateFormat} - ${endDateFormat}`;
      }
    },
    formatDateDisplay(date) {
      return new Date(date).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
    },
    formatDateDisplayShort(date) {
      return new Date(date).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric"
        });
    },
    getDateDiff(param) {
      let getStartDate = _get(param, "startDate", "");
      let getEndDate = _get(param, "endDate", "");
      const date1 = new Date(getStartDate);
      const date2 = new Date(getEndDate);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    },
    getFileURL(filename) {
      if (filename) {
        return `${process.env.VUE_APP_URL}/files/${encodeURIComponent(
          filename
        )}`;
      }
      return "";
    },
    setBackgroundMain() {
      this.setBackgroundFormStepperScreen(2);
    },
    onResendMail() {
      let sendMailObj = {};
      sendMailObj.bookingItem = this.$store.state.booking.bookingData;
      sendMailObj.mailType = "USERSUMMARY";
      this.sendEMail(sendMailObj);
    },
    onExportPdfFile() {
      let bookingData = _get(this, "bookingItem.bookingData", []);
      if (bookingData) {
        this.exportFilePDF(bookingData);
      }
    }
  }
};
</script>

<style scoped>
.summary-header-title {
  font-weight: 400;
  font-size: 13px;
}

.font-text {
  font-size: 14px;
  font-weight: 400;
}

.card-title-font {
  font-weight: 700;
  font-size: 16px;
}

.summary-price {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.summary-total {
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
}

.v-divider-width {
  width: 433.5px;
  text-align: center;
}

.card-padding {
  padding-left: 20px;
  padding-top: 20px;
}

.confirmation--card--bank {
  border: 2px dashed var(--v-primary);
  height: 100%;
}

.confirmation--card--bank--text {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.summary-conference-button {
  text-align: right;
  margin-left: -20px;
}

/* .sumary-border {
  border: 2px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px 8px 0px 0px;
} */

.sumary-footer-bg {
  background-color: #282828;
  background: #282828 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 5px 5px;
}

.sumary-divider-padding {
  margin-top: 26px;
  margin-bottom: 20px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: unset;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Card */
.summary-card {
  background-color: #ffffff !important;
  box-shadow: 0px 0px 15px #00000019 !important;
  border-radius: 10px !important;
  border: none !important;
}
.summary--room--desc {
  padding-bottom: 0px;
  margin-bottom: 18px;
}
.summary--room--date {
  padding-bottom: 16px;
  margin-bottom: 0px;
}
@media (max-width: 480px) {
  .summary--container {
    background-color: #f7f7f8;
  }

  .summary-button {
    font-weight: 700 !important;
    font-size: 14px !important;
    color: #282828 !important;
    height: 50px !important;
    width: 296px !important;
    margin-top: 34px;
    margin-bottom: 0px;
  }

  .p-header-tel {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 33px;
    margin-bottom: 47px;
    letter-spacing: 0;
    line-height: 21px;
  }

  .summary-header-email {
    margin-top: 22px;
    margin-bottom: 0px;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .summary-header-email-subtitle {
    font-weight: 400;
    font-size: 14px;
  }

  .summary--title {
    margin-top: 31px;
  }
  .summary--room--price {
    padding-bottom: 35px;
    margin-bottom: 0px;
  }

  .summary--bottom--container {
    padding-top: 33px;
    margin-bottom: 33px;
  }
  .summary-header-title {
    font-size: 16px;
    margin-top: 0.5px;
  }
  .tooltiptext {
    margin-left: 20px !important;
  }
}

@media (min-width: 481px) {
  .summary-button {
    font-weight: 700 !important;
    font-size: 14px !important;
    color: #282828 !important;
    height: 50px !important;
    width: 296px !important;
    margin-top: 21px;
    margin-bottom: 0px;
  }

  .p-header-tel {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 26px;
    margin-bottom: 43px;
  }
  .summary-header-email {
    margin-top: 28px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0px;
  }
  .summary-header-email-subtitle {
    font-weight: 400;
    font-size: 14px;
  }
  .summary--title {
    margin-top: 31px !important;
  }
  .summary--room--price {
    padding-bottom: 26px;
    margin-bottom: 0px;
  }
  .summary--bottom--container {
    padding-top: 30px;
    margin-bottom: 30px;
  }
}

.pre-formatted {
  white-space: pre;
}
</style>
