<template>
  <v-container>
    <v-card class="mx-auto doc--require--card">
      <v-container class="doc--require--title--section1">
        <p class="mb-0 text--bold--20">อัพโหลดเอกสารที่จำเป็น</p>
        <p class="text--regular--14">
          เอกสารที่ต้องยื่นพร้อมกับคำขอใช้พื้นที่หอศิลป์ร่วมสมัยราชดำเนิน
        </p>
        <p class="mb-0 red--text text--regular--14 doc-require--pdf--text">
          *โปรดอัพโหลดไฟล์เอกสารให้ครบถ้วน
        </p>
        <p class="red--text text--regular--14">*กรุณาอัพโหลดไฟล์ PDF ทั้งหมด</p>
      </v-container>
      <v-divider></v-divider>

      <div v-if="requireDoc !== null">
        <v-container
          class="padding--container--10"
          v-for="(item, index) in requireDoc"
          :key="item.id"
          cols="12"
        >
          <v-row class="doc--require--title--section1">
            <v-col cols="12" md="7">
              <p class="text--medium--14 mb-0">
                {{ item.title }}
                <span class="red--text" v-if="item.is_require">*</span>
              </p>
              <p
                class="mb-0 text--regular--placeholder--14"
                v-if="item.subtitle"
              >
                {{ item.subtitle }}
              </p>
              <p
                v-if="item.originalFileName"
                class="mb-0 docuemt--require--filename"
                style="position:relative;"
              >
                <v-btn
                  v-if="item.fileName && item.originalFileName"
                  elevation="0"
                  class="mx-2"
                  fab
                  dark
                  color="black"
                  style="width: 20px; height: 20px;"
                  @click="removeDocument(index)"
                >
                  <v-icon dark>
                    mdi-minus
                  </v-icon>
                </v-btn>
                {{
                  item.originalFileName.substring(0, 5) +
                    "..." +
                    item.originalFileName.split(".")[1].substring(0, 3)
                }}
              </p>
            </v-col>
            <v-col cols="12" md="5" class="text-end doc--require--uploadbtn">
              <UploadSingleFile @onUpload="file => onUpload(file, index)" />
            </v-col>
            <v-col
              cols="12"
              md="7"
              class="text-start"
              v-if="
                isSubmit &&
                  item.is_require &&
                  !item.fileName &&
                  !item.originalFileName
              "
            >
              <span style="color: #b71c1c; font-size: 12px !important;"
                >กรุณาอัพโหลด เอกสารให้ครบถ้วน</span
              >
            </v-col>
          </v-row>

          <v-divider class="ma-3"></v-divider>
        </v-container>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _get from "lodash/get";
import _filter from "lodash/filter";
import UploadSingleFile from "./UploadSingleFile";

export default {
  components: { UploadSingleFile },
  computed: mapState({
    requireDoc: state => {
      if (_get(state, "applicantdetail.requireDocList", "") === "GUEST") {
        return _get(state, "applicantdetail.requireDocList", []);
      } else {
        return _get(state, "applicantdetail.requireDocList", []);
      }
    }
  }),
  data: () => ({
    isSubmit: false,
    reveal: false,
    iconTrash: require("../assets/icons/icon-trash.svg")
  }),
  methods: {
    ...mapActions("uploadimage", ["uploadImages"]),
    ...mapActions("applicantdetail", ["setOrganizationsType", "setRequireDoc"]),
    onUpload(file, index) {
      let { fileName, originalFileName } = file;
      this.requireDoc[index].fileName = fileName;
      this.requireDoc[index].originalFileName = originalFileName;
      this.setRequireDoc(this.requireDoc);
    },
    removeDocument(index) {
      delete this.requireDoc[index].fileName;
      delete this.requireDoc[index].originalFileName;
      this.setRequireDoc(this.requireDoc);
    },
    onSubmitForm() {
      this.isSubmit = true;
      let filterRequireDoc = _filter(
        this.requireDoc,
        o => o.is_require && !o.fileName && !o.originalFileName
      );
      if (filterRequireDoc.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>
<style scoped>
@media (max-width: 480px) {
  .doc--require--card {
    box-shadow: 0px 0px 15px #496c7733 !important;
    border-radius: 10px !important;
  }
  .doc-require--pdf--text {
  }
  .doc--require--uploadbtn {
    padding: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .doc--require--title--section1 {
    padding: 20px 24px 19px 34px !important;
  }
  .docuemt--require--filename {
    color: #54af5b;
    margin-left: -10px;
    margin-top: 10px;
  }
}

@media (min-width: 481px) {
  .doc--require--card {
    box-shadow: 0px 0px 15px #496c7733 !important;
    border-radius: 10px !important;
  }
  .doc-require--pdf--text {
    margin-top: 9px;
  }
  .doc--require--title--section1 {
    padding: 30px 30px 27px 30px !important;
  }
  .doc--require--uploadbtn {
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .docuemt--require--filename {
    color: #54af5b;
    margin-left: -10px;
    margin-top: 10px;
  }
}
</style>
