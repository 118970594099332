<template>
  <v-container class="applicaion--detail--custom">
    <v-col v-if="
                  applicantType === 'GUEST' || applicantType === 'GOVERNMENTAGENCY' || applicantType === 'PRIVATEAGENCY'
                ">
      <div class="applicaion--text--title--custom">
        <p class="text--bold--24 mb-0 applicaion--title">
          รายละเอียดผู้ขอใช้พื้นที่
        </p>
        <p class="text--regular--14 applicaion--subtitle">
          ท่านสามารถระบุความประสงค์ในการขอยืมได้
          โดยทั้งนี้จะขึ้นอยู่กับความเห็นชอบของคณะกรรมการสำนักหอศิลป์ฯ
        </p>
      </div>
    </v-col>
    <v-row v-if="
                  applicantType === 'GUEST' || applicantType === 'GOVERNMENTAGENCY' || applicantType === 'PRIVATEAGENCY'
                ">
      <v-col cols="12" sm="12" md="7" class="applicaion--detail--custom">
        <div v-if="applicantType == 'GUEST'">
          <GuestInformationForm ref="form" />
        </div>
        <div v-if="applicantType == 'PRIVATEAGENCY'">
          <PrivateAgencyInformationForm ref="form" />
        </div>
        <div v-if="applicantType == 'GOVERNMENTAGENCY'">
          <GovernmentAgencyInformationForm ref="form" />
        </div>
      </v-col>

      <v-col cols="12" sm="12" md="5" class="applicaion--detail--custom">
        <DocumentsRequired ref="docRequire" />
        <v-container class="d-flex flex-row justify-center">
          <v-col
            cols="12"
            sm="8"
            md="8"
            class="d-flex flex-column justify-space-between"
          >
            <v-btn
              rounded
              color="primary"
              dark
              elevation="0"
              class="black--text text--medium--14"
              height="50"
              @click="onSubmit"
            >
              ส่งคำขอใช้พื้นที่
            </v-btn>
          </v-col>
        </v-container>
      </v-col>
    </v-row>
    <v-row align="center"
      justify="center" v-if="
                  applicantType === 'INTERNAL'
                ">
      <v-col cols="12" sm="12" md="8" v-if="
                    applicantType === 'INTERNAL'
                  ">
        <div class="applicaion--text--title--custom">
          <p class="text--bold--24 mb-0 applicaion--title">
            รายละเอียดผู้ขอใช้พื้นที่
          </p>
          <p class="text--regular--14 applicaion--subtitle">
            ท่านสามารถระบุความประสงค์ในการขอยืมได้
            โดยทั้งนี้จะขึ้นอยู่กับความเห็นชอบของคณะกรรมการสำนักหอศิลป์ฯ
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row align="center"
      justify="center" v-if="
                  applicantType === 'INTERNAL'
                ">
      <v-col cols="12" sm="12" md="8" class="applicaion--detail--custom">
        <div v-if="applicantType == 'INTERNAL'">
          <InternalInformationForm ref="form" />
        </div>
        <div class="hidden-form">
          <DocumentsRequired ref="docRequire" />
        </div>
        <v-container class="d-flex flex-row justify-center">
          <v-col
            cols="12"
            sm="12"
            md="8"
            class="d-flex flex-column justify-space-between"
          >
            <v-btn
              rounded
              color="primary"
              dark
              elevation="0"
              class="black--text text--medium--14"
              height="50"
              @click="onSubmit"
            >
              ส่งคำขอใช้พื้นที่
            </v-btn>
          </v-col>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import _get from "lodash/get";
import _map from "lodash/map";

import DocumentsRequired from "@/components/DocumentsRequired.vue";
import GuestInformationForm from "@/components/GuestInformationForm.vue";
import PrivateAgencyInformationForm from "@/components/PrivateAgencyInformationForm.vue";
import GovernmentAgencyInformationForm from "@/components/GovernmentAgencyInformationForm.vue";
import InternalInformationForm from "@/components/InternalInformationForm.vue";

export default {
  data: () => ({ reveal: false }),
  components: {
    DocumentsRequired,
    GuestInformationForm,
    PrivateAgencyInformationForm,
    GovernmentAgencyInformationForm,
    InternalInformationForm
  },
  computed: {
    ...mapGetters("areaspecify", {
      areaCartDepositCalculate: "areaCartDepositCalculate",
      areaCartMaintenanceCostCalculate: "areaCartMaintenanceCostCalculate",
      areaCartSummaryPriceCalculate: "areaCartSummaryPriceCalculate",
      areaGetPricePerRoom: "areaGetPricePerRoom"
    }),
    ...mapGetters("authentication", {
      parseJwt: "parseJwt"
    }),
    ...mapState({
      applicantType: state => state.rentspacecriteria.applicantType
    })
  },
  methods: {
    ...mapActions("booking", ["createBooking"]),
    ...mapActions("booking", ["createBookingItems"]),
    ...mapActions("sendmail", ["sendEMail"]),
    async onSubmit() {
      let parseJwtResult = this.parseJwt;
      let validRequireDoc = this.$refs.docRequire.onSubmitForm();
      let validForm = this.$refs.form.onSubmitForm();
      if (validRequireDoc && validForm) {
        let getInformation = _get(this.$refs.form, "form", null);

        let getBookingItem = _get(
          this.$store.state.confirmation,
          "confirmMationItem.areaRequestLists",
          null
        );
        let getIsRequireAccessory = _get(
          this.$store.state.confirmation,
          "confirmMationItem.isRequireAccessory",
          null
        );
        let getRequireDocFilesUpload = _get(
          this.$refs.docRequire,
          "requireDoc",
          null
        );
        let getApplicantType = _get(
          this.$store.state.rentspacecriteria,
          "applicantType",
          null
        );

        let createBookingResult = await this.createBooking({
          userId: _get(parseJwtResult, "id", ""),
          ...getInformation,
          requireDoc: ((getRequireDocFilesUpload == null) ? [] : getRequireDocFilesUpload),
          isRequireAccessory: getIsRequireAccessory,
          maintenanceCost: this.areaCartMaintenanceCostCalculate,
          depositPrice: this.areaCartDepositCalculate,
          summary: this.areaCartSummaryPriceCalculate,
          applicantType: getApplicantType,
          status: "ยังไม่ดำเนินการ"
        });

        if (createBookingResult.status === 200) {
          let getBookingID = _get(createBookingResult, "data.id", "");
          let currentState = this;
          let getNewBookingItem = _map(getBookingItem, o => {
            let getPrice = currentState.areaGetPricePerRoom(o);
            return {
              userId: _get(parseJwtResult, "id", ""),
              startDate: _get(o, "startDate", ""),
              endDate: _get(o, "endDate", ""),
              objective: _get(o, "objective", ""),
              otherObjective: _get(o, "otherObjective", ""),
              adminId: _get(o, "adminId", ""),
              price: getPrice,
              deposit: _get(o, "deposit", 0),
              bookingId: getBookingID,
              roomId: _get(o, "id", null)
            };
          });

          let createBookingItemResult = await this.createBookingItems(
            getNewBookingItem
          );

          if (createBookingItemResult.status === 200) {
            let sendMailObj = {};
            sendMailObj.bookingItem = this.$store.state.booking.bookingData;
            sendMailObj.mailType = "USERSUMMARY";
            this.sendEMail(sendMailObj);

            this.$emit("clicked", "NEXT");
          } else {
            // todo popup error
          }
        } else {
          // todo popup error
        }
      }
    }
  }
};
</script>

<style scoped>
.application-detail-button {
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 480px) {
  .applicaion--detail--custom {
    padding: 5px !important;
    margin-top: 24px;
  }
  .applicaion--subtitle {
    margin-bottom: 0 !important;
    padding-top: 6px;
  }
  .applicaion--title {
    margin-bottom: 6px;
  }
}

@media (min-width: 481px) {
  .applicaion--text--title--custom {
    padding-left: 3px;
    padding-top: 3px;
  }
  .applicaion--subtitle {
    margin-bottom: 0 !important;
    padding-top: 3px;
  }
  .applicaion--title {
    margin-bottom: 3px;
  }
}

.hidden-form {
  display: none;
}
</style>
