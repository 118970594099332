<template>
  <v-container>
    <p
      class="text--regular--14 confirm--text--title"
      style="width: fit-content; cursor: pointer;"
      @click="onBack"
    >
      <v-icon>mdi-chevron-left</v-icon>ย้อนกลับ
    </p>
    <p class="text--bold--20 mb-0">
      ตรวจสอบรายการห้องจัดแสดงที่ต้องการขอใช้
    </p>
    <p class="mb-0 text--regular--14 confirm--subtitle">
      ท่านสามารถเลือกห้องจัดแสดงได้มากกว่า 1 ห้องโดยกดจองพื้นที่เพิ่ม
    </p>
    <v-container class="pl-0  confirmation-container-area-request-list">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="text-center"
          v-for="(value, index) in areaRequestLists"
          :key="index + '' + value.id"
        >
          <v-card
            class="mx-auto confirmation-card-area-request-list"
            outlined
            elevation="0"
          >
            <v-container class="confirmation-card-area-request-item">
              <v-row class="confirmation-card-area-request-item-height">
                <v-col
                  cols="12"
                  xs="12"
                  sm="4"
                  md="4"
                  class="pb-0 confirmation-card-area-request-item-iamge"
                >
                  <v-list-item-avatar
                    tile
                    width="100%"
                    max-width="143"
                    height="auto"
                  >
                    <img
                      :src="getFileURL(value.imageName)"
                      :alt="value.imageName"
                    />
                  </v-list-item-avatar>
                </v-col>
                <v-col cols="12" xs="12" sm="8" md="8" class="pb-0">
                  <v-list-item-content class="pb-0 text-start">
                    <div>
                      <p
                        class="mb-2  text--bold--18"
                        v-text="value.name + ' - ชั้น ' + value.floor"
                      ></p>
                      <p class="text--regular--14">
                        {{ value.description }}
                      </p>
                      <p class="mb-2 text--regular--14">
                        วันที่ขอใช้ : {{ formatDate(value) }}
                      </p>
                      <p class="mb-2 text--regular--14">
                        ความจุ : {{ value.capacity }}
                      </p>
                      <p class="text--regular--14">
                        ขนาดพื้นที่ : {{ value.size }}
                      </p>
                    </div>
                    <div>
                      <p class="text--bold--14 mb-1">
                        โปรดเลือกวัตถุประสงค์
                        <span class="text-required">*</span>
                      </p>
                      <p
                        class="mb-1 text--regular--14 text-required"
                        v-if="areaRequestLists[index].isError"
                      >
                        กรุณาระบุ ระบุวัตถุประสงค์อื่น
                      </p>
                      <div style="margin-bottom: 1%">
                        <v-checkbox
                          :ripple="false"
                          @change="
                            e =>
                              onChangeObjectTive(
                                e,
                                'ใช้เป็นห้องจัดนิทรรศการ/Workshop',
                                index
                              )
                          "
                          class="mt-0 confirmation-card-area-checkbox "
                          color="black"
                          v-model="areaRequestLists[index].objective"
                          value="ใช้เป็นห้องจัดนิทรรศการ/Workshop"
                        >
                          <template v-slot:label>
                            <p
                              class="mb-0 text--regular--14"
                              style="color: #282828;"
                            >
                              ใช้เป็นห้องจัดนิทรรศการ/Workshop
                            </p>
                          </template>
                        </v-checkbox>
                      </div>
                      <div style="margin-bottom: 1%">
                        <v-checkbox
                          :ripple="false"
                          class="mt-0 confirmation-card-area-checkbox"
                          color="black"
                          value="ใช้เป็นห้องประชุม/อบรม/สัมมนา"
                          v-model="areaRequestLists[index].objective"
                          @change="
                            e =>
                              onChangeObjectTive(
                                e,
                                'ใช้เป็นห้องประชุม/อบรม/สัมมนา',
                                index
                              )
                          "
                        >
                          <template v-slot:label>
                            <p
                              class="mb-0 text--regular--14"
                              style="color: #282828;"
                            >
                              ใช้เป็นห้องประชุม/อบรม/สัมมนา
                            </p>
                          </template>
                        </v-checkbox>
                      </div>
                      <div style="margin-bottom: 1%">
                        <v-checkbox
                          class="mt-0 confirmation-card-area-checkbox"
                          color="black"
                          :ripple="false"
                          value="ใช้เป็นห้องจัดแถลงข่าว"
                          v-model="areaRequestLists[index].objective"
                          @change="
                            e =>
                              onChangeObjectTive(
                                e,
                                'ใช้เป็นห้องจัดแถลงข่าว',
                                index
                              )
                          "
                        >
                          <template v-slot:label>
                            <p
                              class="mb-0 text--regular--14"
                              style="color: #282828;"
                            >
                              ใช้เป็นห้องจัดแถลงข่าว
                            </p>
                          </template>
                        </v-checkbox>
                      </div>
                      <div style="margin-bottom: 1%">
                        <v-checkbox
                          :ripple="false"
                          class="mt-0 mb-0 confirmation-card-area-checkbox "
                          @change="e => onChangeObjectTive(e, 'อื่นๆ', index)"
                          color="black"
                          value="อื่นๆ"
                          v-model="areaRequestLists[index].objective"
                        >
                          <template v-slot:label class="mt-0 ">
                            <p
                              class="mb-0 text--regular--14"
                              style="color: #282828;"
                            >
                              อื่นๆ
                            </p>
                          </template>
                        </v-checkbox>
                      </div>
                      <v-row
                        v-show="
                          areaRequestLists[index].objective.indexOf('อื่นๆ') !==
                            -1
                        "
                      >
                        <v-col>
                          <v-expand-transition>
                            <div>
                              <v-textarea
                                outlined
                                :value="areaRequestLists[index].otherObjective"
                                rows="2"
                                @change="e => onChangeOtherObject(index, e)"
                                row-height="15"
                                label="ระบุวัตถุประสงค์อื่น ๆ"
                              >
                              </v-textarea>
                            </div>
                          </v-expand-transition>
                        </v-col>
                      </v-row>

                      <div
                        class="d-flex flex-row justify-end mb-2 "
                        style="margin-top: -10px;"
                      >
                        <v-btn @click="selectedRemoveItem(value)" icon>
                          <img :src="iconTrash" alt="icon trash" srcset="" />
                        </v-btn>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-col>
              </v-row>

              <v-row
                v-show="
                  areaRequestLists[index].objective.indexOf('อื่นๆ') !== -1
                "
              >
                <v-col>
                  <v-expand-transition>
                    <div class="text-start confirmation--expand--container">
                      <v-card-text> </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
          <v-dialog
            v-model="removeDialog"
            max-width="702"
            :retain-focus="false"
          >
            <v-card class="pa-10 confirmation-popup-card">
              <v-card-title class="confirmation-popup-title">
                <p class="mb-0 popup-comfirm-delete-text">
                  ต้องการลบพื้นที่นี้ใช่หรือไม่?
                </p>
              </v-card-title>
              <v-card-actions class="popup-comfirm-delete">
                <v-btn
                  elevation="0"
                  class="popup-comfirm-delete-button mb-2"
                  outlined
                  rounded
                  color="black"
                  @click="removeArea()"
                  width="200"
                >
                  <p class="mb-0 black--text">
                    ยืนยันการลบ
                  </p>
                </v-btn>
                <v-btn
                  elevation="0"
                  rounded
                  class="popup-comfirm-delete-button mb-2"
                  color="primary"
                  @click="removeDialog = false"
                  width="200"
                >
                  <p class="mb-0 black--text ">
                    ไม่ต้องการ
                  </p>
                </v-btn>
              </v-card-actions>
              <v-btn
                :ripple="false"
                fab
                text
                small
                color="grey darken-2"
                @click="removeDialog = false"
                class="confirmation--closeicon"
              >
                <v-icon large :ripple="false">
                  mdi-close
                </v-icon>
              </v-btn>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" xl="6">
          <v-card class="confirmation--card--bank" outlined elevation="0">
            <div class="confirmation--card--bank--text" @click="onBack">
              <p class="mb-0 text--regular--16">
                <v-icon large class="pr-2 primary--text">mdi-plus-circle</v-icon
                >เพิ่มพื้นที่จัดกิจกรรมอื่น ๆ
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <p class="text-required mb-0 mt-3" v-if="
                  applicantType === 'GUEST' || applicantType === 'GOVERNMENTAGENCY' || applicantType === 'PRIVATEAGENCY'
                ">
      *หากมีการดำเนินการใดๆ ล่วงเวลาทำการ
      ผู้ขอใช้พื้นที่จะต้องมีค่าล่วงเวลาให้กับพนักงานตามอัตราที่ระเบียบราชการกำหนดไว้
    </p>
    <v-divider class="confirm--divider--custom"></v-divider>
    <v-row v-if="
                  applicantType === 'GUEST' || applicantType === 'GOVERNMENTAGENCY' || applicantType === 'PRIVATEAGENCY'
                ">
      <v-col cols="12" sm="7" md="7">
        <a
          href="/1620485908300_RCAC_รายการครุภัณฑ์_วัสดุ_อุปกรณ์.pdf"
          style="text-decoration: none; color: black;"
          target="_blank"
        >
          <p class="mb-0 text--bold--20">
            อุปกรณ์เสริมภายในวันจัดงาน*
            <img
              :src="iconQuestion"
              alt="icon question"
              srcset=""
              style="cursor: pointer;"
            />
          </p>
        </a>
        <p class="font-text onfirmation-footer-detail">
          (*สามารถแจ้งกับเจ้าหน้าที่ก่อนวันจัดงานหรือก่อนเวลาจัดงาน)
        </p>
        <v-checkbox
          class="font-footer-text"
          v-model="isRequireAccessory"
          color="black"
        >
          <template v-slot:label>
            <p class="mb-0" style="color: #282828;">
              ต้องการอุปกรณ์เสริมภายในวันจัดงาน
            </p>
          </template>
        </v-checkbox>
      </v-col>
      <v-col cols="12" sm="5" md="5">
        <v-row class="pt-0 pb-0">
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="text-start pb-0"
            style="padding-top: 5px;"
          >
            <p class="text--regular--16 mb-0">
              ค่าเงินบำรุงรักษา (อัตราค่าเช่า)
            </p>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="text-end pb-0"
            style="padding-top: 5px;"
          >
            <p class="text--regular--16 mb-0">
              {{ areaCartMaintenanceCostCalculate.toLocaleString() }} บาท
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="6" md="6" class="text-start pb-0">
            <p class="text--regular--16 mb-0">ค่าประกันสัญญา</p>
          </v-col>
          <v-col cols="6" sm="6" md="6" class="text-end pb-0">
            <p class="text--regular--16 mb-0">
              {{ areaCartDepositCalculate.toLocaleString() }} บาท
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="text-start"
            style="padding-top: 10px;"
          >
            <p class="text--bold--18 mb-0 ">ราคาทั้งหมด</p>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            md="6"
            class="text-end"
            style="padding-top: 10px;"
          >
            <p class="text--bold--18 mb-0">
              {{ areaCartSummaryPriceCalculate.toLocaleString() }} บาท
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-container
      class="d-flex flex-row justify-center"
      style="width: 100%; text-align: center;"
    >
      <v-col cols="12" sm="4" md="3">
        <v-btn
          rounded
          color="primary"
          dark
          elevation="0"
          class="black--text text--medium--14 confirm--btn--custom"
          @click="onSubmitSummary"
        >
          ยืนยันพื้นที่ที่เลือก
        </v-btn>
      </v-col>
    </v-container>

    <v-dialog v-model="dialogDupDateDate" max-width="702" :retain-focus="false">
      <v-card class="pa-10 confirmation-popup-card">
        <v-card-title class="confirmation-popup-title">
          <p class="mb-0 popup-comfirm-delete-text">
            มีการทำรายการ {{ dialogDupName }} ในวันที่เลือกไปแล้ว
            กรุณาเลือกวันที่หรือพื้นที่ใหม่
          </p>
        </v-card-title>
        <v-card-actions class="popup-comfirm-delete">
          <v-btn
            elevation="0"
            rounded
            class="popup-comfirm-delete-button mb-2"
            color="primary"
            @click="dialogDupDateDate = false"
            width="200"
          >
            <p class="mb-0 black--text">
              ตกลง
            </p>
          </v-btn>
        </v-card-actions>
        <v-btn
          :ripple="false"
          fab
          text
          small
          color="grey darken-2"
          @click="dialogDupDateDate = false"
          class="confirmation--closeicon"
        >
          <v-icon large :ripple="false">
            mdi-close
          </v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

// import TimePicker from "./TimePicker";
import _get from "lodash/get";
import _reduce from "lodash/reduce";
import _findIndex from "lodash/findIndex";

export default {
  // components: {
  //   TimePicker
  // },
  data: () => ({
    dialogDupDateDate: false,
    dialogDupName: "",
    removeDialog: false,
    iconTrash: require("../assets/icons/icon-trash.svg"),
    iconQuestion: require("../assets/icons/icon-question.svg"),
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    isRequireAccessory: false,
    menuStartTime: false,
    menuEndTime: false,
    removeItem: null,
    baseURL: process.env.VUE_APP_URL
  }),
  computed: {
    ...mapState({
      areaRequestLists: state => {
        return [...state.areaspecify.areaCart];
      },
      totalSummary: state => {
        let getAreaCart = _get(state.areaspecify, "areaCart");
        let reduceTotoalPrice = _reduce(
          getAreaCart,
          (sum, n) => {
            return sum + Number(n.price);
          },
          0
        );
        return reduceTotoalPrice;
      },
      totalDeposit: state => {
        let getAreaCart = _get(state.areaspecify, "areaCart");
        let reduceTotoalDeposit = _reduce(
          getAreaCart,
          (sum, n) => {
            let getStartDate = _get(n, "startDate", "");
            let getEndDate = _get(n, "endDate", "");
            const date1 = new Date(getStartDate);
            const date2 = new Date(getEndDate);
            const diffTime = Math.abs(date2 - date1);
            let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            diffDays += 1;
            return sum + Math.ceil(diffDays / 7) * 10000;
          },
          0
        );
        return reduceTotoalDeposit;
      }
    }),
    ...mapGetters("areaspecify", {
      areaCartSummaryPriceCalculate: "areaCartSummaryPriceCalculate",
      areaCartMaintenanceCostCalculate: "areaCartMaintenanceCostCalculate",
      areaCartDepositCalculate: "areaCartDepositCalculate",
      getIsAvailableAreaForDate: "getIsAvailableAreaForDate"
    })
  },
  methods: {
    ...mapActions("areaspecify", [
      "setAreaSelected",
      "setObjective",
      "setAreaConfirmationSelected",
      "setErrorAreaCart",
      "setOtherObjective"
    ]),
    ...mapActions("confirmation", ["submitConfirmMation"]),
    ...mapActions("booking", ["getBookingItemHistoryTemp"]),
    onBack() {
      this.setAreaConfirmationSelected([...this.areaRequestLists]);
      this.$emit("clicked", "BACK");
    },
    async onSubmitSummary() {
      // let getYear = new Date().getFullYear();
      // let getCurrentMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
      // let getPrevMonth = ("0" + new Date().getMonth()).slice(-2);
      // let getNextMonth = ("0" + (new Date().getMonth() + 2)).slice(-2);
      // let getNextMonth2 = ("0" + (new Date().getMonth() + 3)).slice(-2);
      // let getNextMonth3 = ("0" + (new Date().getMonth() + 4)).slice(-2);

      const filtersBookingHistory = {
        order: "id asc",
        // where: {
        //   or: [
        //     {
        //       startDate: {
        //         regexp: `${getYear}-${getCurrentMonth}.*`,
        //         options: "i"
        //       }
        //     },
        //     {
        //       startDate: {
        //         regexp: `${getYear}-${getPrevMonth}.*`,
        //         options: "i"
        //       }
        //     },
        //     {
        //       startDate: {
        //         regexp: `${getYear}-${getNextMonth}.*`,
        //         options: "i"
        //       }
        //     },
        //     {
        //       startDate: {
        //         regexp: `${getYear}-${getNextMonth2}.*`,
        //         options: "i"
        //       }
        //     },
        //     {
        //       startDate: {
        //         regexp: `${getYear}-${getNextMonth3}.*`,
        //         options: "i"
        //       }
        //     }
        //   ],
        //   roomId: this.roomID
        // },
        include: [
          {
            relation: "booking"
          }
        ]
      };
      let response = await this.getBookingItemHistoryTemp(
        filtersBookingHistory
      );
      if (response) {
        if (response.status === 200) {
          let isDupName = this.getIsAvailableAreaForDate;
          if (!isDupName) {
            let createRoomBooking = {
              areaRequestLists: [...this.areaRequestLists],
              isRequireAccessory: this.isRequireAccessory
            };
            let filterIsConf = _findIndex(
              this.areaRequestLists,
              o => o.objective.length === 0
            );
            let filterIsOtherObjective = _findIndex(
              this.areaRequestLists,
              o => {
                return (
                  o.objective.indexOf("อื่นๆ") !== -1 && o.otherObjective === ""
                );
              }
            );
            if (filterIsConf === -1 && filterIsOtherObjective === -1) {
              this.submitConfirmMation(createRoomBooking);
              this.$emit("clicked", "NEXT");
            } else {
              if (filterIsConf !== -1) {
                this.setErrorAreaCart(filterIsConf);
              } else {
                this.setErrorAreaCart(filterIsOtherObjective);
              }
            }
          } else {
            this.dialogDupName = isDupName;
            this.dialogDupDateDate = true;
          }
        }
      }
    },
    removeArea() {
      this.removeItem;
      let startDate = _get(this.removeItem, "startDate", null);
      let endDate = _get(this.removeItem, "endDate", null);
      if (startDate !== null && endDate !== null) {
        this.setAreaSelected({
          item: this.removeItem,
          startDate,
          endDate,
          isRemove: true
        });
      }
      this.removeDialog = false;
    },
    getFileURL(filename) {
      if (filename) {
        return `${process.env.VUE_APP_URL}/files/${encodeURIComponent(
          filename
        )}`;
      }
      return "";
    },
    formatDate(param) {
      let getStartDate = _get(param, "startDate", "");
      let getEndDate = _get(param, "endDate", "");
      if (getStartDate && getEndDate) {
        let startDate = new Date(getStartDate);
        let endDate = new Date(getEndDate);
        let startDateFormat = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        ).toLocaleDateString("th-TH", {
          month: "long",
          day: "numeric"
        });
        let endDateFormat = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate()
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
        return `${startDateFormat} - ${endDateFormat}`;
      }
      return "";
    },
    onChangeObjectTive(event, value, index) {
      this.setObjective({ event, value, index });
    },
    getDateDiff(param) {
      let getStartDate = _get(param, "startDate", "");
      let getEndDate = _get(param, "endDate", "");
      const date1 = new Date(getStartDate);
      const date2 = new Date(getEndDate);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays + 1;
    },
    selectedRemoveItem(item) {
      this.removeItem = item;
      this.removeDialog = true;
    },
    onChangeOtherObject(index, text) {
      this.setOtherObjective({ index, text });
    },
    getAccessoryFile() {
      return `${this.baseURL}/files/${encodeURIComponent(
        "1620485908300_RCAC_รายการครุภัณฑ์_วัสดุ_อุปกรณ์.pdf"
      )}`;
    }
  }
};
</script>

<style scoped>
.confirmation--card--bank {
  border: 2px dashed var(--v-primary);
  height: 242px;
  width: auto;
  border-radius: 10px;
}

.confirmation--card--bank--text {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.confirmation--closeicon {
  position: absolute;
  top: 0px;
  right: 0px;
}

.confirmation-list-item {
  display: flex;
}

.confirmation-popup-card {
  box-shadow: 0px 0px 15px #496c7733 !important;
  border-radius: 10px;
}

.confirmation-popup-title {
  display: flex;
  justify-content: center;
}

.popup-comfirm-delete-text {
  font-weight: 700;
  font-size: 21px;
}

.popup-comfirm-delete-button {
  font-weight: 500;
  font-size: 14px;
}

.popup-comfirm-delete {
  display: flex;
  justify-content: center;
}

.text-required {
  color: red;
}

/* Padding Section */
.confirmation-card-list-item-padding {
  padding-top: 30px;
  padding-left: 27px;
}

.confirmation-card-description-padding {
  padding-top: 1px;
}

.confirmation-card-date-padding {
  padding-top: 12px;
}

.confirmation-room-image {
  width: 100%;
  max-width: 143px;
  height: auto;
}

.comfirmation-card-exhibition-area {
  padding-top: 24px;
}

.confirmation-title {
  padding-top: 8.6px;
}

.confirmation-checkbox {
  margin-top: 9px;
}

.confirmation-maintenance-cost {
  padding-top: 5px;
}

.confirmation-summary-price {
  padding-top: 10px;
}

.confirmation-card-area-request-item {
  padding-top: 30px;
  padding-left: 27px;
}

.confirmation-card-area-request-item-height {
  max-height: initial;
  min-height: auto;
}

.confirmation-card-area-request-item-iamge {
  text-align: center;
}

.confirmation-card-area-expand-timepicker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.confirm--btn--custom {
  height: 50px !important;
  width: 251px !important;
}

.confirmation-card-area-checkbox {
  max-height: 30px !important;
}

@media (max-width: 480px) {
  .confirm--text--title {
    margin-bottom: 9px;
  }

  .confirmation-card-area-request-list {
    box-shadow: 0px 0px 15px #496c7733 !important;
    border-radius: 10px;
  }

  .confirmation-container-area-request-list {
    margin-top: 27px;
    padding-top: 27px;
    padding: 0;
  }

  .confirm--subtitle {
    margin-top: 7px;
  }

  .confirm--divider--custom {
    /* margin-top: 35px; */
    margin-top: 20px;
    margin-bottom: 26px;
  }
  .confirmation--expand--container {
    display: none;
  }
}
@media (max-width: 959px) {
  .confirmation--expand--container {
    display: none;
  }
}

@media (min-width: 481px) {
  .confirm--text--title {
    margin-bottom: 16px;
  }

  .confirmation-card-area-request-list {
    box-shadow: 0px 0px 15px #496c7733 !important;
    border-radius: 10px;
  }

  .confirmation-container-area-request-list {
    padding-top: 27px;
  }

  .confirm--subtitle {
    margin-top: 1px;
  }

  .confirm--divider--custom {
    margin-top: 39px;
    margin-bottom: 24px;
  }

  .confirmation--expand--container {
    height: 85px;
  }
}
</style>
