<template>
  <div>
    <vue-timepicker
      v-model="timeValue"
      :hour-range="[9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]"
      fixed-dropdown-button
      input-class="time--picker--style--custom"
      @change="setTime"
       hide-clear-button
    >
      <template v-slot:dropdownButton>
        <v-icon>mdi-chevron-down</v-icon>
      </template>
    </vue-timepicker>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";

import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: {
    VueTimepicker
  },
  data: () => ({
    timeValue: {
      HH: "",
      mm: ""
    }
  }),
  methods: {
    setTime(time) {
      if (time.displayTime) {
        this.$emit("submitTime", time.displayTime);
      }
    }
  }
};
</script>

<style>
.time--picker--style--custom {
  border-radius: 4px;
  height: 40px !important;
}
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: var(--v-primary);
}
</style>
