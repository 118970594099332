<template>
  <v-container style="margin-top: 21px;">
    <p class="date-specify-title text--bold--20">กรุณาระบุวันที่จัดงาน</p>
    <v-row>
      <v-col sm="12" md="12" class="pb-0">
        <v-card class="mx-auto datespecify-card">
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="4" class="pb-md-3 pb-sm-0 pb-0">
                <v-col cols="12" sm="6" md="12" class="pb-md-3 pb-sm-0 pb-0">
                  <v-menu
                    ref="startDateDialog"
                    v-model="startDateModal"
                    :close-on-content-click="true"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrStartDate }">
                      <p class="date-specify-start-date-title">
                        วันที่เริ่มจัด
                      </p>
                      <v-text-field
                        :value="formatStartDate"
                        outlined
                        readonly
                        v-bind="attrStartDate"
                        v-on="on"
                        dense
                        class="text--regular--14"
                      >
                        <template v-slot:append>
                          <v-fade-transition leave-absolute>
                            <img
                              width="24"
                              height="24"
                              src="../assets/icons/calendar.svg"
                              alt=""
                            />
                          </v-fade-transition>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      id="date-specify--custom"
                      v-model="startDate"
                      no-title
                      scrollable
                      locale="th-TH"
                      color="primary"
                      :allowed-dates="getAllowedStartDates"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="2"
                class="datespecify-schedule-day pb-md-3 pb-sm-0 pt-md-3 pt-sm-0 pt-0 pb-0"
              >
                <v-container
                  class="datespecify-schedule-info pb-md-3 pb-sm-0 pt-md-3 pt-sm-0 pt-0 pb-0 mt-sm-n5 mt-n5 mt-md-0"
                >
                  <v-checkbox
                    class="datespecity-schedule-day"
                    v-model="oneDateCheckbox"
                    @click="onOneDateChecked"
                    color="#282828"
                    label="จัด 1 วัน"
                  >
                  </v-checkbox>
                </v-container>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="4"
                class="pb-md-3 pb-sm-0 pt-md-3 pt-sm-0 pt-0 pb-0"
              >
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  class="pb-md-3 pb-sm-0 pt-md-3 pt-sm-0 pt-0 pb-0"
                >
                  <v-menu
                    id="datedate-specify-container"
                    ref="endDateDialog"
                    v-model="endDateModal"
                    :close-on-content-click="true"
                    :return-value.sync="endDate"
                    transition="scale-transition"
                    offset-y
                    persistent
                    width="290px"
                  >
                    <template
                      id="datedate-specify-container-template"
                      v-slot:activator="{ on, attrsEndDate }"
                    >
                      <p class="date-specify-end-date-title text--bold--14">
                        วันที่สิ้นสุด
                      </p>
                      <v-text-field
                        :value="formatEndDate"
                        outlined
                        readonly
                        v-bind="attrsEndDate"
                        v-on="on"
                        dense
                        class="text--regular--14"
                      >
                        <template v-slot:append>
                          <v-fade-transition leave-absolute>
                            <img
                              width="24"
                              height="24"
                              src="../assets/icons/calendar.svg"
                              alt=""
                            />
                          </v-fade-transition>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      id="date-specify--custom"
                      v-model="endDate"
                      no-title
                      scrollable
                      locale="th-TH"
                      color="primary"
                      :allowed-dates="getAllowedEndDates"
                    >
                      <v-spacer></v-spacer>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="2"
                class="date-specify-btn-confirm-date-section pt-sm-0 pt-0"
              >
                <v-btn
                  rounded
                  color="primary"
                  dark
                  elevation="0"
                  @click="submitDateCriteria"
                  class="date-specify-btn-confirm-date"
                >
                  <!-- <v-btn rounded color="primary" dark elevation="0" @click="submitDateCriteria"
                  > -->
                  <span class="date-specify-btn-confirm-date-text"
                    >ยืนยันวันที่</span
                  >
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _get from "lodash/get";
import moment from "moment";

let currentDate = moment().add(91, "days").format('YYYY-MM-DD');

export default {
  data: () => ({
    addDate: 91,
    startDate: currentDate,
    endDate: currentDate,
    startDateLabel: currentDate,
    endDateLabel: currentDate,
    startDateModal: false,
    endDateModal: false,
    oneDateCheckbox: true
  }),
  props: {
    isElevation: {
      type: Boolean,
      default: true
    }
  },
  destroyed() {
    // document.documentElement.style.overflow = "auto";
  },
  mounted: function() {
    this.$nextTick(function() {
      // document.documentElement.style.overflow = "hidden";

      let getApplicantType = _get(
        this.$store.state.rentspacecriteria,
        "applicantType",
        null
      );

      if(getApplicantType == 'INTERNAL') {
        currentDate = moment().add(1, "days").format('YYYY-MM-DD');

        this.addDate = 1;
      } else {
        currentDate = moment().add(91, "days").format('YYYY-MM-DD');

        this.addDate = 91;
      }

      this.startDate = currentDate;
      this.endDate = currentDate;
      this.startDateLabel = currentDate;
      this.endDateLabel = currentDate;

    });
  },
  watch: {
    endDate() {
      this.$refs.endDateDialog.save(this.endDate);
      if (this.endDate !== this.startDate) {
        this.oneDateCheckbox = false;
      }
      if (this.endDate === this.startDate) {
        this.oneDateCheckbox = true;
      }
      this.setOnSubmitDate(false);
      // document.documentElement.style.overflow = "hidden";
    },
    startDate() {
      this.$refs.startDateDialog.save(this.startDate);
      if (this.startDate !== this.endDate) {
        this.oneDateCheckbox = false;
      }
      if (this.startDate > this.endDate) {
        this.endDate = this.startDate;
      }
      if (this.startDate === this.endDate) {
        this.oneDateCheckbox = true;
      }
      this.setOnSubmitDate(false);
      // document.documentElement.style.overflow = "hidden";
    }
  },
  computed: {
    formatStartDate() {
      let startDate = new Date(this.startDate);
      return new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      ).toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    },
    formatEndDate() {
      let endDate = new Date(this.endDate);
      return new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate()
      ).toLocaleDateString("th-TH", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    },
    ...mapState({
      applicantType: state => state.rentspacecriteria.applicantType
    })
  },
  methods: {
    ...mapActions("datespecify", ["submitDate", "setOnSubmitDate"]),
    ...mapActions("areaspecify", ["getAreaLists"]),
    submitDateCriteria() {
      this.submitDate({
        startDate: this.startDate,
        endDate: this.endDate,
        isOneDaySelected: this.oneDateCheckbox
      });
      const filters = {
        offset: 0,
        limit: 100,
        skip: 0,
        where: {
          ismaintenance: false
        }
      };
      this.getAreaLists(filters);
      this.setOnSubmitDate(true);
      document.documentElement.style.overflow = "auto";
      this.setScrollToAreaSpecify(); //Start : Sasiprapa Banyen : 2021-04-27
    },
    onOneDateChecked() {
      if (this.oneDateCheckbox) {
        this.endDate = this.startDate;
      }
      if (this.startDate === this.endDate) {
        this.oneDateCheckbox = true;
      }
      this.setOnSubmitDate(false);
    },
    getAllowedStartDates(val) {
      // Start : Sasiprapa B. : 2021-04-28
      // return moment(val).isSameOrAfter(moment(this.currentDate).add(91, "days"), "date");
      // End : Sasiprapa B. : 2021-04-28
      return moment(val).isSameOrAfter(moment().add(this.addDate, "days"), "date");
      // return moment(val).isSameOrAfter(moment(), "date");
    },
    getAllowedEndDates(val) {
      // Start : Sasiprapa B. : 2021-04-28
      // return moment(val).isSameOrAfter(moment().add(91, "days"), "date");
      // End : Sasiprapa B. : 2021-04-28
      return moment(val).isSameOrAfter(moment(this.startDate), "date"); // Sasiprapa Comment
    },
    // Start : Sasiprapa Banyen : 2021-04-27
    setScrollToAreaSpecify() {
      document.getElementById("area-specify-page").scrollIntoView({
        behavior: "smooth"
      });
    }
    // End : Sasiprapa Banyen : 2021-04-27
  }
};
</script>
<style scoped>
.datespecify-schedule-day {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.datespecify-schedule-info {
  display: flex;
  justify-content: center;
}

.datespecify-card {
  box-shadow: 0px 0px 15px #496c7733 !important;
  border-radius: 5px;
}

.date-specify-title {
  width: 175px;
  height: 30px;
  line-height: 30px;
  text-align: left;
  color: #282828;
  margin-bottom: 17px;
}

.date-specify-start-date-title {
  width: 63px;
  height: 21px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 21px;
  text-align: left;
  color: #282828;
  margin-bottom: 7px;
}

.date-specify-end-date-title {
  width: 61px;
  height: 21px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 21px;
  text-align: left;
  color: #282828;
  margin-bottom: 7px;
}

/* .datespecity-schedule-day {
  width: 47px;
  height: 21px;
  line-height: 21px;
  text-align: left;
  color: #282828;
} */
.date-specify-btn-confirm-date-text {
  width: 58px;
  height: 21px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #282828;
}

.date-specify-btn-confirm-date {
  max-width: 162px !important;
  height: 50px !important;
  border-radius: 25px;
  padding: 15px 52px 14px 52px !important;
}

.date-specify-btn-confirm-date-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0px;
}

/* Mobile */
@media (max-width: 959px) {
  .date-specify-btn-confirm-date-section {
    flex-direction: row;
    /* margin-top: 26px; */
    margin-bottom: 31px;
  }
  .date-specify-btn-confirm-date {
    max-width: 192px !important;
    min-width: 192px !important;
    height: 45px !important;
    border-radius: 25px;
    padding: 11px 62px 10px 63px;
  }
  .date-specify-btn-confirm-date-text {
    font-weight: 500;
    font-size: 16px;
    width: 67px;
    height: 24px;
    line-height: 24px;
    text-align: left;
    color: #282828;
  }
}
</style>
