<template>
  <div class="upload--single--file">
    <label class="text--regular--14 border--color--gray d-flex justify-center">
      <input
        type="file"
        id="file"
        ref="file"
        v-on:change="handleFileUpload()"
        class="inputfile"
        accept="application/pdf"
      />
      <span
        class="ma-2 text--regular--14 border--color--gray"
        style="cursor: pointer;"
        >อัพโหลดไฟล์</span
      >
    </label>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _get from "lodash/get";

export default {
  data() {
    return {
      file: "",
      isError: false
    };
  },
  methods: {
    ...mapActions("uploadfile", ["uploadFile"]),
    async handleFileUpload() {
      this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      let fileUploadResult = await this.uploadFile(formData);
      if (fileUploadResult.status === 200) {
        let getFilename = _get(
          fileUploadResult,
          "data.files[0].originalname",
          ""
        );
        this.$emit("onUpload", {
          fileName: getFilename,
          originalFileName: this.file.name
        });
        this.file = null;
        this.$refs.file.value = null;
      }
    }
  }
};
</script>

<style scoped>
.inputfile {
  /* visibility: hidden etc. wont work */
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile:focus + label {
  /* keyboard navigation */
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.inputfile + label * {
  pointer-events: none;
}
.upload--single--file {
  cursor: pointer;
  height: 36px;
  min-width: 64px;
  border: thin solid;
  border-color: #d1d1d1 !important;
  border-radius: 5px;
}
</style>
