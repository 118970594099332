var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"applicaion--detail--custom"},[(
                _vm.applicantType === 'GUEST' || _vm.applicantType === 'GOVERNMENTAGENCY' || _vm.applicantType === 'PRIVATEAGENCY'
              )?_c('v-col',[_c('div',{staticClass:"applicaion--text--title--custom"},[_c('p',{staticClass:"text--bold--24 mb-0 applicaion--title"},[_vm._v(" รายละเอียดผู้ขอใช้พื้นที่ ")]),_c('p',{staticClass:"text--regular--14 applicaion--subtitle"},[_vm._v(" ท่านสามารถระบุความประสงค์ในการขอยืมได้ โดยทั้งนี้จะขึ้นอยู่กับความเห็นชอบของคณะกรรมการสำนักหอศิลป์ฯ ")])])]):_vm._e(),(
                _vm.applicantType === 'GUEST' || _vm.applicantType === 'GOVERNMENTAGENCY' || _vm.applicantType === 'PRIVATEAGENCY'
              )?_c('v-row',[_c('v-col',{staticClass:"applicaion--detail--custom",attrs:{"cols":"12","sm":"12","md":"7"}},[(_vm.applicantType == 'GUEST')?_c('div',[_c('GuestInformationForm',{ref:"form"})],1):_vm._e(),(_vm.applicantType == 'PRIVATEAGENCY')?_c('div',[_c('PrivateAgencyInformationForm',{ref:"form"})],1):_vm._e(),(_vm.applicantType == 'GOVERNMENTAGENCY')?_c('div',[_c('GovernmentAgencyInformationForm',{ref:"form"})],1):_vm._e()]),_c('v-col',{staticClass:"applicaion--detail--custom",attrs:{"cols":"12","sm":"12","md":"5"}},[_c('DocumentsRequired',{ref:"docRequire"}),_c('v-container',{staticClass:"d-flex flex-row justify-center"},[_c('v-col',{staticClass:"d-flex flex-column justify-space-between",attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-btn',{staticClass:"black--text text--medium--14",attrs:{"rounded":"","color":"primary","dark":"","elevation":"0","height":"50"},on:{"click":_vm.onSubmit}},[_vm._v(" ส่งคำขอใช้พื้นที่ ")])],1)],1)],1)],1):_vm._e(),(
                _vm.applicantType === 'INTERNAL'
              )?_c('v-row',{attrs:{"align":"center","justify":"center"}},[(
                  _vm.applicantType === 'INTERNAL'
                )?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8"}},[_c('div',{staticClass:"applicaion--text--title--custom"},[_c('p',{staticClass:"text--bold--24 mb-0 applicaion--title"},[_vm._v(" รายละเอียดผู้ขอใช้พื้นที่ ")]),_c('p',{staticClass:"text--regular--14 applicaion--subtitle"},[_vm._v(" ท่านสามารถระบุความประสงค์ในการขอยืมได้ โดยทั้งนี้จะขึ้นอยู่กับความเห็นชอบของคณะกรรมการสำนักหอศิลป์ฯ ")])])]):_vm._e()],1):_vm._e(),(
                _vm.applicantType === 'INTERNAL'
              )?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"applicaion--detail--custom",attrs:{"cols":"12","sm":"12","md":"8"}},[(_vm.applicantType == 'INTERNAL')?_c('div',[_c('InternalInformationForm',{ref:"form"})],1):_vm._e(),_c('div',{staticClass:"hidden-form"},[_c('DocumentsRequired',{ref:"docRequire"})],1),_c('v-container',{staticClass:"d-flex flex-row justify-center"},[_c('v-col',{staticClass:"d-flex flex-column justify-space-between",attrs:{"cols":"12","sm":"12","md":"8"}},[_c('v-btn',{staticClass:"black--text text--medium--14",attrs:{"rounded":"","color":"primary","dark":"","elevation":"0","height":"50"},on:{"click":_vm.onSubmit}},[_vm._v(" ส่งคำขอใช้พื้นที่ ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }