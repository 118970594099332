<template>
  <v-container padless>
    <v-card
      flat
      tile
      class="background--back white--text text-center"
      width="100%"
    >
      <v-card-text class="white--text padding-card">
        <v-container class="pt-0 pt-sm-0 pt-md-3">
          <v-row class="mt-0">
            <v-col
              cols="3"
              sm="0"
              md="2"
              offset-md="1"
              class="area-cart-area-request"
            >
              <p class="mb-0 text--bold--14">พื้นที่ที่ต้องการขอใช้</p>
              <p class="mb-0 text--regular--14">
                จำนวน {{ areaCart.length }} พื้นที่
              </p>
            </v-col>
            <v-col class="text-start" v-if="
                  applicantType === 'GUEST' || applicantType === 'GOVERNMENTAGENCY' || applicantType === 'PRIVATEAGENCY'
                ">
              <p
                class="mb-0 text--bold--14"
                v-if="
                  applicantType === 'GUEST' || applicantType === 'PRIVATEAGENCY'
                "
              >
                ราคา (ไม่รวมค่าประกันสัญญา)
              </p>
              <p v-else class="mb-0 text--bold--14">
                ราคา
              </p>
              <p class="mb-0 text--regular--14">
                {{ areaCartSummaryPriceCalculate.toLocaleString() }} บาท
              </p>
            </v-col>
            <v-col style="text-align: right;">
              <v-btn
                rounded
                elevation="0"
                class="black--text text--medium--14 button-summary"
                @click="onSelectedAreaCart"
              >
                สรุปรายการ
              </v-btn>
              <v-dialog
                v-model="removeDialog"
                max-width="702"
                :retain-focus="false"
              >
                <v-card class="pa-10 confirmation-popup-card">
                  <v-card-title class="confirmation-popup-title">
                    <p class="mb-0 popup-comfirm-delete-text hidden-xs-only">
                      ต้องการดำเนินการต่อโดยไม่เพิ่มพื้นที่ในวันอื่น
                    </p>
                    <p
                      class="mb-0 text-center popup-comfirm-delete-text hidden-sm-and-up hidden-md-and-up hidden-sm-and-up hidden-xl-and-up"
                    >
                      ต้องการดำเนินการต่อโดย <br />
                      ไม่เพิ่มพื้นที่ในวันอื่น
                    </p>
                  </v-card-title>
                  <v-card-actions class="popup-comfirm-delete">
                    <v-btn
                      elevation="0"
                      class="popup-comfirm-delete-button mb-2"
                      outlined
                      rounded
                      color="black"
                      @click="onSummaryClick()"
                      width="200"
                    >
                      <p class="mb-0 black--text">
                        สรุปรายการ
                      </p>
                    </v-btn>
                    <v-btn
                      elevation="0"
                      rounded
                      class="popup-comfirm-delete-button mb-2"
                      color="primary"
                      @click="removeDialog = false"
                      width="200"
                    >
                      <p class="mb-0 black--text">
                        เลือกวันที่และพื้นที่ต่อ
                      </p>
                    </v-btn>
                  </v-card-actions>
                  <v-btn
                    :ripple="false"
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="removeDialog = false"
                    class="confirmation--closeicon"
                  >
                    <v-icon large :ripple="false">
                      mdi-close
                    </v-icon>
                  </v-btn>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
export default {
  name: "areacart",
  props: {
    // Start : Sasiprapa B. : 2021-04-23
    backToPreviousStep: {
      type: Boolean,
      default: false
    }
    // End : Sasiprapa B. : 2021-04-23
  },
  data: () => ({
    removeDialog: false,
    itemTemp: [] //Sasiprapa B. : 2021-04-23
  }),
  created() {},
  computed: {
    ...mapState({
      areaCart: state => {
        return state.areaspecify.areaCart;
      },
      areaCartConfirmation: state => {
        return state.areaspecify.areaCartConfirmation;
      }
    }),
    ...mapGetters("areaspecify", {
      areaCartSummaryPriceCalculate: "areaCartSummaryPriceCalculate"
    }),
    ...mapState({
      applicantType: state => {
        return state.rentspacecriteria.applicantType;
      }
    })
  },
  methods: {
    ...mapActions("areaspecify", ["getAreaCart"]),
    ...mapMutations(["setAreaCart"]),
    onSelectedAreaCart() {
      //1. validate route
      if (this.backToPreviousStep) {
        //2. validate data by id
        let difference = this.areaCart.filter(
          x => !this.areaCartConfirmation.includes(x)
        );
        if (difference.length === 0) {
          this.removeDialog = true;
        } else {
          this.removeDialog = false;
          this.$emit("clicked", "NEXT");
        }
      } else {
        this.$emit("clicked", "NEXT");
      }
      document.documentElement.style.overflow = "auto";
    },
    onSummaryClick() {
      this.removeDialog = false;
      this.$emit("clicked", "NEXT");
    }
  }
};
</script>
<style scoped>
.background--back {
  background-color: #282828;
}

.padding-card {
  padding: 0px;
}

.button-summary {
  width: 198px;
  height: 45px;
}

.area-cart-area-request {
  text-align: left;
  margin-left: 40px;
}
/* dialog comfirm */
.confirmation--closeicon {
  position: absolute;
  top: 0px;
  right: 0px;
}

.confirmation-list-item {
  display: flex;
}

.confirmation-popup-title {
  display: flex;
  justify-content: center;
}

.popup-comfirm-delete-text {
  font-weight: 700;
  font-size: 20px;
}

.popup-comfirm-delete-button {
  font-weight: 500;
  font-size: 14px;
}

.popup-comfirm-delete {
  display: flex;
  justify-content: center;
}
.confirmation-popup-card {
  box-shadow: 0px 0px 15px #496c7733 !important;
  border-radius: 10px;
}
</style>
