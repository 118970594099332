<template>
  <v-container
    id="area-specify-page"
    v-if="availableArea !== null"
    class="area-specify-container"
  >
    <div
      :class="
        getRequestType == 'AREA'
          ? 'area-specify-area-container'
          : 'area-specify-date-container'
      "
    >
      <div
        :class="
          getRequestType == 'AREA'
            ? 'area-specify-header-area-container'
            : 'area-specify-header-date-container'
        "
      >
        <p class="area-specify-header">เลือกพื้นที่ที่ต้องการขอใช้</p>
      </div>
      <div v-for="item in availableArea" :key="item.id">
        <!-- <div class="area--specify--card--container"> -->
        <div
          :class="
            getRequestType == 'AREA'
              ? 'area-specify-card-area-container'
              : 'area-specify-card-date-container'
          "
        >
          <v-card class="mx-auto area-specify-card" outlined>
            <v-container>
              <v-row class="mt-2">
                <v-col cols="6" sm="6" md="4" class="text-center">
                  <v-container
                    class="pt-5"
                    style="cursor: pointer;"
                    @click="onOpenPopupImage(item.videoName)"
                  >
                    <img
                      :src="getFileURL(item.imageName)"
                      :alt="item.imageName"
                      srcset=""
                      width="257"
                      height="auto"
                    />
                    <div class="areaspecify--text--desc--container">
                      <img width="33" height="21" :src="icon360" alt="" />
                      <p class="mb-0 text--bold--14 areaspecify--text--desc">
                        ดูตัวอย่างพื้นที่
                      </p>
                    </div>
                  </v-container>
                </v-col>
                <v-col cols="6" sm="6" md="5">
                  <p class="text--bold--18 mb-0">{{ item.name }}</p>
                  <p class="text--regular--14">{{ item.description }}</p>
                  <v-divider></v-divider>
                  <p class="text--regular--14 mb-0 mt-4">
                    ความจุ : {{ item.capacity.toLocaleString() }} คน
                  </p>
                  <p class="text--regular--14">
                    ขนาดพื้นที่ : {{ item.size.toLocaleString() }} ตารางเมตร
                  </p>
                  <p class="text--bold--14 mb-0" v-if="
                  applicantType === 'GUEST' || applicantType === 'GOVERNMENTAGENCY' || applicantType === 'PRIVATEAGENCY'
                ">
                    อัตราค่าเช่า : {{ item.price.toLocaleString() }} บาท/วัน
                  </p>
                  <div class="tooltip primary--text" style="cursor: pointer;" v-if="
                  applicantType === 'GUEST' || applicantType === 'GOVERNMENTAGENCY' || applicantType === 'PRIVATEAGENCY'
                ">
                    ค่าประกันสัญญา
                    <v-icon class="primary--text"
                      >mdi-help-circle-outline</v-icon
                    >
                    <span class="tooltiptext"
                      >เพิ่ม 10,000 บาทในทุกๆ 7 วัน</span
                    >
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="3"
                  class="area-specify-content-button"
                >
                  <div class="area-specify-content-button-text-align">
                    <v-btn
                      v-if="!item.isSelected && getRequestType !== 'AREA'"
                      width="100%"
                      rounded
                      color="primary"
                      dark
                      elevation="0"
                      class="area-specify-content-select-are-request-btn"
                      @click="onSelectedArea(item)"
                    >
                      <p
                        class="area-specify-content-select-are-request-btn-text"
                      >
                        เลือกขอใช้พื้นที่
                      </p>
                    </v-btn>
                    <div
                      class="ma-2"
                      v-if="
                        getRequestType === 'AREA' && item.isSelected === false
                      "
                    >
                      <PopupCalendar
                        :isShowDatePicker="true"
                        :roomName="item.name"
                        :roomID="item.id"
                        :editArea="false"
                        @submit="() => onSelectedArea(item)"
                      />
                    </div>
                    <div>
                      <v-btn
                        v-if="item.isSelected"
                        width="100%"
                        rounded
                        color="black"
                        dark
                        elevation="0"
                        class="area-specify-content-select-are-btn"
                      >
                        <v-icon>mdi-check</v-icon>
                        <p class="area-specify-content-select-are-btn-text">
                          เลือกพื้นที่
                        </p>
                      </v-btn>
                      <!-- <div class="area-specify-content-select-are-btn-text-detail" v-if="item.isSelected"> -->
                      <div
                        class="area-specify-content-select-are-btn-text-detail"
                      >
                        <div
                          class="area-specify-content-select-are-btn-text-detail-container"
                        >
                          <div
                            class="areaspecify--dup--userbooking-margin"
                            v-if="
                              getUserBookingCount(item.id) > 0 &&
                                getRequestType !== 'AREA'
                            "
                          >
                            <span
                              class="areaspecify--dup--userbooking text--medium--14"
                              >มีผู้ขอใช้วันเดียวกับท่าน
                              {{ getUserBookingCount(item.id) }} คน</span
                            >
                          </div>
                          <div
                            v-if="item.isSelected && getRequestType == 'AREA'"
                          >
                            <PopupCalendar
                              style="
                                  justify-content: center !important; 
                                  margin-top: 14px;
                              "
                              :isShowDatePicker="true"
                              :roomName="item.name"
                              :roomID="item.id"
                              :editArea="true"
                              @submit="
                                editArea => onSelectedArea(item, editArea)
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="area-specify-content-popupCalendar-button">
                    <PopupCalendar
                      :isShowDatePicker="false"
                      :roomName="item.name"
                      :roomID="item.id"
                      :editArea="false"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <div
              class="area-specify-card-select-date"
              v-if="item.isSelected && getRequestType == 'AREA'"
            >
              <p class="mb-0 area-specify-card-select-date-title">
                วันที่จัดงานที่เลือก
              </p>
              <p
                class="mb-0 area-specify-card-select-date-detail"
                v-text="formatDateDatespecify(item)"
              ></p>
            </div>
          </v-card>

          <div
            class="area--specify--card--card--label"
            :class="item.floor == '1' ? ' area-specify-card-floor' : ''"
          >
            <p class="mb-0 white--text text--bold--20">ชั้น {{ item.floor }}</p>
          </div>
        </div>
      </div>

      <div class="text-center">
        <v-dialog v-model="imageDialog" max-width="900">
          <v-card height="500">
            <iframe target="_top" width="900" height="500" :src="popUpImageUrl">
            </iframe>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <div
      v-if="areaspecify.length > 0"
      class="as-actiontray as--actiontray--sticky--actiontray"
    >
      <AreaCart
        :backToPreviousStep="backToPreviousStep"
        @clicked="onSelectedAreaCart"
        class="padding--areacrat"
      >
      </AreaCart>
    </div>
    <v-overlay
      :absolute="absolute"
      :opacity="opacity"
      :value="getOpacityClass"
      :light="true"
      :color="getBackgroundColor"
    >
    </v-overlay>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import PopupCalendar from "@/components/PopupCalendar.vue";
import AreaCart from "@/components/AreaCart.vue";
import _get from "lodash/get";

export default {
  components: {
    PopupCalendar,
    AreaCart
  },
  props: {
    isElevation: {
      type: Boolean,
      default: true
    },
    // Start : Sasiprapa B. : 2021-04-23
    backToPreviousStep: {
      type: Boolean,
      default: false
    }
    // End : Sasiprapa B. : 2021-04-23
  },
  data: () => ({
    absolute: true,
    opacity: 0.8,
    imageDialog: false,
    isClickedArea: false,
    alert: true,
    isShowDeposit: false,
    icon360: require("../assets/icons/icon-360.svg"),
    popUpImageUrl: "",
    scrolled: false,
    visiblePopupCalendarEditArea: false //Start : Sasiprapa B. : 2021-05-03
  }),
  created() {
    if (this.$store.state.rentspacecriteria.requestType === "DATE") {
      document.documentElement.style.overflow = "hidden";
    }
    const filters = {
      offset: 0,
      limit: 100,
      skip: 0,
      where: {
        ismaintenance: false
      }
    };

    this.getAreaLists(filters);
    this.setOnSubmitDate(true);

    // get booking history
    // let getYear = new Date().getFullYear();
    // let getCurrentMonth = ("0" + (new Date().getMonth() + 1)).slice(-2);
    // let getPrevMonth = ("0" + new Date().getMonth()).slice(-2);
    // let getNextMonth = ("0" + (new Date().getMonth() + 2)).slice(-2);
    // let getNextMonth2 = ("0" + (new Date().getMonth() + 3)).slice(-2);
    // let getNextMonth3 = ("0" + (new Date().getMonth() + 4)).slice(-2);

    const filtersBookingHistory = {
      order: "id asc",
      // where: {
      //   or: [
      //     {
      //       startDate: {
      //         regexp: `${getYear}-${getCurrentMonth}.*`,
      //         options: "i"
      //       }
      //     },
      //     {
      //       startDate: {
      //         regexp: `${getYear}-${getPrevMonth}.*`,
      //         options: "i"
      //       }
      //     },
      //     {
      //       startDate: {
      //         regexp: `${getYear}-${getNextMonth}.*`,
      //         options: "i"
      //       }
      //     },
      //     {
      //       startDate: {
      //         regexp: `${getYear}-${getNextMonth2}.*`,
      //         options: "i"
      //       }
      //     },
      //     {
      //       startDate: {
      //         regexp: `${getYear}-${getNextMonth3}.*`,
      //         options: "i"
      //       }
      //     }
      //   ],
      //   roomId: this.roomID
      // },
      include: [
        {
          relation: "booking"
        }
      ]
    };
    this.getBookingItemHistoryTemp(filtersBookingHistory);
    // }
  },
  destroyed() {
    document.documentElement.style.overflow = "auto";
  },
  computed: {
    ...mapState({
      applicantType: state => state.rentspacecriteria.applicantType,
      areaspecify: state => {
        return state.areaspecify.areaCart;
      },
      getRequestType: state => {
        return state.rentspacecriteria.requestType;
      },
      getOpacityClass: state => {
        return (
          (state.rentspacecriteria.requestType === "DATE" &&
            state.datespecify.isSubmitDate === false) ||
          (state.rentspacecriteria.requestType === "DATE" &&
            !state.datespecify.startDate &&
            !state.datespecify.endDate)
        );
      },
      getBackgroundColor: state => {
        return state.app.backgroundcolor;
      }
    }),
    ...mapGetters("booking", {
      getUserBookingCount: "getUserBookingCount"
    }),
    ...mapGetters("areaspecify", {
      availableArea: "getAvailableAreaForDate"
    })
  },

  methods: {
    ...mapActions("areaspecify", ["getAreaLists", "setAreaSelected"]),
    ...mapActions("datespecify", ["setOnSubmitDate"]),
    ...mapActions("booking", [
      "getBookingItemHistory",
      "getBookingItemHistoryTemp"
    ]),
    onSelectedArea(item, editArea) {
      let startDate = _get(this.$store.state, "datespecify.startDate", null);
      let endDate = _get(this.$store.state, "datespecify.endDate", null);
      if (startDate !== null && endDate !== null) {
        this.setAreaSelected({
          item,
          startDate,
          endDate,
          isRemove: false,
          editArea
        });
      }
    },
    onOpenPopupImage(url) {
      if (this.isValidHttpUrl(url)) {
        // let splitUrl = url.split("&list")[0];
        // splitUrl = splitUrl.replace("watch?v=", "embed/");
        // this.popUpImageUrl = splitUrl;
        // this.imageDialog = true;
        window.open(url, "_blank");
      }
    },
    getFileURL(filename) {
      if (filename) {
        return `${process.env.VUE_APP_URL}/files/${encodeURIComponent(
          filename
        )}`;
      } else {
        return "";
      }
    },
    onSelectedAreaCart(text) {
      this.isClickedArea = true;
      if (
        this.$store.state.rentspacecriteria.requestType === "DATE" &&
        text === "NEXT"
      ) {
        this.setOnSubmitDate(false);
      }
      this.$emit("clicked", text);
    },
    getFileUrl() {
      return `${process.env.VUE_APP_URL}/files/1618233384074_yt1s.com-Shortclip_v144P.mp4`;
    },
    isValidHttpUrl(string) {
      try {
        new URL(string);
      } catch (_) {
        return false;
      }
      return true;
    },
    // Start : Sasiprapa B.: 2021-05-03
    onSelectedEditAreaSpecify() {
      this.visiblePopupCalendarEditArea = true;
    },
    formatDateDatespecify(item) {
      let areaCartLsit = _get(this.$store.state, "areaspecify.areaCart", null);
      let areaCart = areaCartLsit.find(result => result.id == item.id);
      let getStartDate = areaCart.startDate;
      let getEndDate = areaCart.endDate;
      if (getStartDate && getEndDate) {
        let startDate = new Date(getStartDate);
        let endDate = new Date(getEndDate);
        let startDateFormat = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate()
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
        let endDateFormat = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate()
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
        return `${startDateFormat} - ${endDateFormat}`;
      }
    }
    // End : Sasiprapa B.: 2021-05-03
  }
};
</script>

<style scoped>
/* container Header Section */
.area-specify-container {
  position: relative;
  padding-top: 0px;
}
/* Header Section */
.area-specify-header {
  /* Layout Properties */
  max-width: 208px;
  max-height: 30px;
  /* UI Properties */
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  color: #282828;
  margin-bottom: 0px;
}
.area-specify-header-area-container {
  text-align: left;
}
.area-specify-header-date-container {
  text-align: left;
}
/* AREA Section */
.area-specify-area-container {
  margin-top: 38px;
}

/* Date Section */
.area-specify-date-container {
  margin-top: 56px;
}
/* Card Area Section */
.area-specify-card-area-container {
  position: relative;
  margin-top: 38px;
}
/* Card Date Section */
.area-specify-card-date-container {
  position: relative;
  margin-top: 32px;
}

.area--specify--card--opacirt {
  opacity: 0.3;
}

.area--specify--card--card--label {
  position: absolute;
  top: 0;
  padding: 5px 20px;
  border-radius: 10px 0px;
  background: #d67c00;
}

.area-specify-card-floor {
  background: var(--v-primary);
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: unset;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.area-specify-content-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0px;
}

.area-specify-content-button-text-align {
  text-align: right;
}

.areaspecify--dup--userbooking {
  color: #4a8901;
}

.area-specify-card {
  box-shadow: 0px 0px 15px #496c7733 !important;
  border-radius: 10px;
}

.areaspecify--text--desc {
  padding-left: 6px;
}

.area-specify-card-select-date {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: #282828;
  height: 48px;
}

.area-specify-card-select-date-title {
  font-weight: 700;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 19px;
  margin-right: 18.76px;
}

.area-specify-card-select-date-detail {
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 19px;
}

/* Button */
.area-specify-content-select-are-request-btn {
  max-width: 198px !important;
  height: 50px !important;
  border-radius: 25px;
  padding: 15px 57px 14px 55px !important;
}

.area-specify-content-select-are-request-btn-text {
  max-width: 86px;
  height: 21px;
  font-weight: 500;
  font-size: 14px;
  text-align: left;
  color: #282828;
  margin-bottom: 0px;
}

.area-specify-content-select-are-btn {
  max-width: 198px !important;
  height: 50px !important;
  border-radius: 25px;
  background-color: #282828;
  padding: 15px 57px 14px 55px !important;
}

.area-specify-content-select-are-btn-text {
  max-width: 58px;
  height: 21px;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  color: #ffffff;
  margin-bottom: 0px;
}

.area-specify-edit-btn {
  max-width: 29px;
  height: 21px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
  color: #282828;
  letter-spacing: 0;
}

.area-specify-content-popupCalendar-button {
  margin-right: 43px;
  margin-bottom: 35px;
}

.areaspecify--dup--userbooking-margin {
  margin-top: 10px;
}

.area-specify-content-select-are-btn-text-detail {
  text-align: -webkit-right;
}

.area-specify-content-select-are-btn-text-detail-container {
  max-width: 198px;
  text-align: center;
}
@media (max-width: 480px) {
  .areaspecify--text--desc--container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 18px;
  }
}
@media (min-width: 481px) {
  .areaspecify--text--desc--container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
  }
}
/* Phone Section */
@media (max-width: 600px) {
  /* AREA Section */
  .area-specify-area-container {
    margin-top: 25px;
  }
  .area-specify-header-area-container {
    display: flex;
    justify-content: center;
  }
  /* Date Section */
  .area-specify-date-container {
    margin-top: 43px;
  }
  /* Card Area Section */
  .area-specify-card-area-container {
    position: relative;
    margin-top: 27px;
  }
  /* Card Date Section */
  .area-specify-card-date-container {
    position: relative;
    margin-top: 22px;
  }
}
@media (max-width: 960px) {
  /* Button Section */
  .area-specify-content-button {
    padding-bottom: 21px;
    padding-top: 43px;
  }
  .area-specify-content-popupCalendar-button {
    margin-right: 0px;
    margin-bottom: 0px;
    margin-top: 32px;
  }
  .areaspecify--dup--userbooking-margin {
    margin-top: 13px !important;
  }
  .area-specify-content-select-are-btn-text-detail-container {
    max-width: unset !important;
  }
}
</style>
