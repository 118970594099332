<template>
  <div>
    <v-row>
      <v-card
        class="d-flex align-stretch mb-6 flex-wrap justify-start"
        color="lighten-2"
        flat
        tile
      >
        <v-card
          v-for="(image, n) in imagesURL"
          :key="n"
          class="pa-2 ma-1"
          outlined
          tile
          height="100"
          width="100"
          style="position:relative;"
        >
          <img width="100px" style="height: 100%;" :src="image" />
          <v-btn
            icon
            color="black"
            style="position:absolute; top:0; right:0;"
            @click="removeImage(n)"
          >
            <v-icon dark>mdi-minus</v-icon>
          </v-btn>
        </v-card>
        <label>
          <input
            type="file"
            @change="onFileChange"
            accept="image/jpeg,image/png"
            ref="file"
            multiple
          />
          <v-icon class="guestinfo--upload--container ma-1">mdi-plus</v-icon>
        </label>
      </v-card>
    </v-row>
    <span v-if="isError" class="red--text">ขนาดรูปมีขนาดใหญ่เกิน 5 mb</span>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _get from "lodash/get";
export default {
  data: () => ({
    images: [],
    imagesURL: [],
    file: "",
    isError: false
  }),
  methods: {
    ...mapActions("uploadfile", ["uploadFile", "setFileImageActivity"]),
    async onFileChange(e) {
      let selectedFiles = e.target.files;
      this.file = selectedFiles;

      if (!this.file) {
        return;
      }

      if (this.file.size > 5242880) {
        this.isError = true;
        return;
      }

      let storeFileNameList = [];
      for (let i = 0; i < this.file.length; i++) {
        let url = URL.createObjectURL(this.file[i]);
        this.imagesURL.push(url);

        let formData = new FormData();
        formData.append("file", this.file[i]);
        let fileUploadResult = await this.uploadFile(formData);
        if (fileUploadResult.status === 200) {
          let getFilename = _get(
            fileUploadResult,
            "data.files[0].originalname",
            ""
          );
          storeFileNameList.push(getFilename);
        }
      }

      this.$emit("onUpload", storeFileNameList);
    },
    removeImage(index) {
      this.imagesURL.splice(index, 1);
      this.$emit("onRemoveFile", index);
    }
  }
};
</script>

<style scoped>
.guestinfo--upload--container {
  position: relative;
  width: 100px;
  height: 100px;
  border: 2px dashed #d1d1d1;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}
</style>
