<template>
  <v-form ref="formAddress" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <p class="mb-0 text--bold--14 formaddress--label">
          บ้านเลขที่ <span class="red--text">*</span>
        </p>
        <v-text-field
          class="text--regular--placeholder--14"
          v-model="formAddress.address"
          :rules="[v => !!v || 'กรุณาระบุ บ้านเลขที่']"
          placeholder="บ้านเลขที่"
          required
          single-line
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <p class="mb-0 text--bold--14 formaddress--label">
          จังหวัด<span class="red--text">*</span>
        </p>
        <v-autocomplete
          placeholder="จังหวัด"
          v-model="formAddress.province"
          :items="provinceList"
          outlined
          item-text="provinceName"
          dense
          return-object
          required
          @change="onChangeProvince"
          :rules="[v => !!v || 'กรุณาระบุ จังหวัด']"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <p class="mb-0 text--bold--14 formaddress--label">
          เขต / อำเภอ<span class="red--text">*</span>
        </p>
        <v-autocomplete
          placeholder="เขต / อำเภอ"
          v-model="formAddress.district"
          :items="districtList"
          outlined
          item-text="districtName"
          dense
          return-object
          required
          @change="onChangeDistrict"
          :rules="[v => !!v || 'กรุณาระบุ เขต / อำเภอ']"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <p class="mb-0 text--bold--14 formaddress--label">
          แขวง / ตำบล<span class="red--text">*</span>
        </p>
        <v-autocomplete
          placeholder="แขวง / ตำบล"
          v-model="formAddress.subdistrict"
          :items="subdistrictList"
          outlined
          item-text="subDistrictName"
          dense
          return-object
          required
          @change="onChangSubDistrict"
          :rules="[v => !!v || 'กรุณาระบุ แขวง / ตำบล']"
        />
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <p class="mb-0 text--bold--14 formaddress--label">
          รหัสไปรษณีย์<span class="red--text">*</span>
        </p>
        <v-autocomplete
          placeholder="รหัสไปรษณีย์"
          v-model="formAddress.zipcode"
          :items="zipcodeList"
          outlined
          item-text="zipcodeName"
          dense
          @change="onSearchZipcode"
          return-object
          required
          :rules="[v => !!v || 'กรุณาระบุ รหัสไปรษณีย์']"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <p class="mb-0 text--bold--14 formaddress--label">
          เบอร์โทรติดต่อ<span class="red--text">*</span>
        </p>
        <v-text-field
          v-mask="'##########'"
          class="text--regular--placeholder--14"
          v-model="formAddress.mobile"
          :rules="mobileRules"
          placeholder="เบอร์โทรติดต่อ"
          required
          single-line
          outlined
          dense
          counter
          maxlength="10"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import _filter from "lodash/filter";
import _get from "lodash/get";

import { zipcode } from "../assets/address/zipcode.json";
import { subdistrict } from "../assets/address/subdistrict.json";
import { province } from "../assets/address/province.json";
import { district } from "../assets/address/district.json";
import { mapActions } from "vuex";

export default {
  directives: { mask },
  data() {
    return {
      valid: true,
      isSubmit: false,
      formAddress: {
        zipcode: _get(this.$store, "state.user.userProfile.zipcode", "") || "",
        address: _get(this.$store, "state.user.userProfile.address", ""),
        subdistrict:
          _get(this.$store, "state.user.userProfile.subdistrict", "") || "",
        province:
          _get(this.$store, "state.user.userProfile.province", "") || "",
        district:
          _get(this.$store, "state.user.userProfile.district", "") || "",
        mobile: _get(this.$store, "state.user.userProfile.mobile", "") || ""
        // zipcode: "",
        // address: "",
        // subdistrict: "",
        // province: "",
        // district: "",
        // mobile: "",
      },
      zipcodeListTemp: zipcode,
      subdistrictListTemp: subdistrict,
      provinceListTemp: province,
      districtListTemp: district,
      zipcodeList: zipcode,
      provinceList: province,
      districtList: district,
      subdistrictList: subdistrict,
      mobileRules: [
        v => {
          return /^[0-9]{10}$/i.test(v) || " กรุณาระบุเบอร์โทรศัพท์ ให้ถูกต้อง";
        }
      ]
    };
  },
  created() {
    this.getUserProfile();
  },
  methods: {
    ...mapActions("user", ["getUserProfile"]),
    onSearchZipcode(zipcode) {
      let filterSubdistrict = _filter(
        this.subdistrictList,
        o => o.subDistrictCode === _get(zipcode, "subDistrictCode", "")
      );
      let filterDistrictList = _filter(
        this.districtList,
        o => o.districtID === _get(filterSubdistrict, "[0].districtID", "")
      );
      let filterProvinceList = _filter(
        this.provinceList,
        o => o.provinceID === _get(filterSubdistrict, "[0].provinceID", "")
      );

      this.formAddress.subdistrict = _get(
        filterSubdistrict,
        "[0].subDistrictName",
        ""
      );
      this.formAddress.province = _get(
        filterProvinceList,
        "[0].provinceName",
        ""
      );
      this.formAddress.district = _get(
        filterDistrictList,
        "[0].districtName",
        ""
      );
      this.formAddress.zipcode = _get(zipcode, "zipcodeName", "");
    },
    onChangeProvince(province) {
      this.formAddress.district = "";
      this.formAddress.subdistrict = "";
      this.formAddress.zipcode = "";
      this.districtList = _filter(
        this.districtListTemp,
        o => o.provinceID === province.provinceID
      );
    },
    onChangeDistrict(district) {
      this.formAddress.subdistrict = "";
      this.formAddress.zipcode = "";
      this.subdistrictList = _filter(
        this.subdistrictListTemp,
        o => o.districtID === district.districtID
      );
    },
    onChangSubDistrict(subDistrict) {
      this.formAddress.zipcode = "";
      this.zipcodeList = _filter(
        this.zipcodeListTemp,
        o => o.subDistrictCode === subDistrict.subDistrictCode
      );
    }
  }
};
</script>

<style scoped>
.formaddress--label {
  margin-bottom: 10px !important;
}
</style>
