<template>
  <v-container>
    <v-card class="mx-auto government--info--card">
      <v-container class="government--info--container">
        <p class="text--bold--18 government--form--label">
          ประเภทผู้ขอใช้ : หน่วยงานภายใน (เฉพาะเจ้าหน้าที่ สศร.)
        </p>
        <v-divider class="mb-2 mt-2"></v-divider>
        <v-form ref="form" v-model="valid" lazy-validation>
          <p class="mb-0 text--bold--14 government--form--label">
            หน่วยงาน<span class="red--text">*</span> ( ศูนย์ฯ / สำนัก / สถาบัน )
          </p>
          <v-select
            :items="departmentItemList"
            dense
            outlined
            item-text="department_name"
            return-object
            :rules="[v => !!v || 'กรุณาเลือก หน่วยงาน']"
            v-model="form.department_name"
            placeholder="หน่วยงาน"
            @change="selectInternalDepartment"
          ></v-select>
          <p class="mb-0 text--bold--14 government--form--label">
            กลุ่มงาน<span class="red--text">*</span>
          </p>
          <v-select
            :items="groupItemList"
            dense
            outlined
            item-text="group_name"
            return-object
            :rules="[v => !!v || 'กรุณาเลือก กลุ่มงาน']"
            v-model="form.group_name"
            placeholder="กลุ่มงาน"
          ></v-select>
          <p class="text--bold--18 government--form--label">
            ข้อมูลเจ้าหน้าที่รับผิดชอบ
          </p>
          <v-divider class="mb-2 mt-2"></v-divider>
          <p class="mb-0 text--bold--14 guestinfo--form--label">
            เจ้าหน้าที่รับผิดชอบ<span class="red--text">*</span>
          </p>
          <v-text-field
            class="text--regular--placeholder--14"
            v-model="form.thname"
            :rules="thaiNameRule"
            placeholder="ชื่อ - นามสกุล"
            required
            single-line
            outlined
            dense
          ></v-text-field>
          <p class="mb-0 text--bold--14 formaddress--label">
            หมายเลขโทรศัพท์ภายใน<span class="red--text">*</span>
          </p>
          <v-text-field
            v-mask="'##########'"
            class="text--regular--placeholder--14"
            v-model="form.tel"
            :rules="telRules"
            placeholder="หมายเลขโทรศัพท์ภายใน"
            required
            single-line
            outlined
            dense
            counter
            maxlength="9"
          ></v-text-field>
          <p class="mb-0 text--bold--14 formaddress--label">
            หมายเลขโทรศัพท์เคลื่อนที่<span class="red--text">*</span>
          </p>
          <v-text-field
            v-mask="'##########'"
            class="text--regular--placeholder--14"
            v-model="form.mobile"
            :rules="mobileRules"
            placeholder="หมายเลขโทรศัพท์เคลื่อนที่"
            required
            single-line
            outlined
            dense
            counter
            maxlength="10"
          ></v-text-field>
          <v-divider class="pt-5"></v-divider>
          <p class="text--bold--18 government--form--label">
            ข้อมูลการจัดนิทรรศการ
          </p>
          <v-divider class="mb-2 mt-2"></v-divider>
          <p class="mb-0 text--bold--14 government--form--label">
            ชื่องาน<span class="red--text">*</span>
          </p>
          <v-text-field
            class="text--regular--placeholder--14"
            v-model="form.activities"
            :rules="[v => !!v || 'กรุณาระบุ ชื่องาน']"
            placeholder="ชื่องาน"
            required
            single-line
            outlined
            dense
          ></v-text-field>
          <p class="mb-0 text--bold--14 government--form--label">
            ประเภทของงาน / รายละเอียด<span class="red--text">*</span>
          </p>
          <v-textarea
            class="text--regular--placeholder--14"
            v-model="form.activities_detail"
            :rules="[v => !!v || 'กรุณาระบุ ประเภทของงาน / รายละเอียด']"
            placeholder="ประเภทของงาน / รายละเอียด"
            rows="5"
            required
            outlined
            dense
          ></v-textarea>
          <v-divider class="pt-5"></v-divider>
          <p class="text--bold--18 government--form--label">
            ข้อมูลห้องนิทรรศการ
          </p>
          <v-divider class="mb-2 mt-2"></v-divider>
          <p class="mb-0 text--bold--14 government--form--label">
            ห้อง / พื้นที่จัดงาน
          </p>
          <v-text-field
            class="text--regular--placeholder--14 disabled"
            :value="areaspecify"
            single-line
            outlined
            dense
            :disabled="true"
          ></v-text-field>
          <v-row>
            <v-col cols="12" sm="12" md="6" class="pb-md-3 pb-sm-0 pb-0">
              <v-menu
                ref="startSetupDateDialog"
                v-model="startSetupDateModal"
                :close-on-content-click="true"
                :return-value.sync="startSetupDate"
                transition="scale-transition"
                offset-y
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrStartSetupDate }">
                  <p class="mb-0 text--bold--14 government--form--label">
                    วันที่เริ่มติดตั้ง<span class="red--text">*</span>
                  </p>
                  <v-text-field
                    :value="formatStartSetupDate"
                    outlined
                    readonly
                    v-bind="attrStartSetupDate"
                    v-on="on"
                    dense
                    class="text--regular--14"
                    required
                    :rules="[v => !!v || 'กรุณาระบุ วันที่เริ่มติดตั้ง']"
                  >
                    <template v-slot:append>
                      <v-fade-transition leave-absolute>
                        <img
                          width="24"
                          height="24"
                          src="../assets/icons/calendar.svg"
                          alt=""
                        />
                      </v-fade-transition>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  id="date-specify--custom"
                  v-model="startSetupDate"
                  no-title
                  scrollable
                  locale="th-TH"
                  color="primary"
                  :allowed-dates="getAllowedStartSetupDates"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pb-md-3 pb-sm-0 pb-0">
              <p class="mb-0 text--bold--14 government--form--label">
                เวลาเริ่มติดตั้ง<span class="red--text">*</span>
              </p>
              <TimePicker @submitTime="startSetupTimeData($event)"></TimePicker>
              <div v-if="!startSetupTimeCheck" class="v-messages theme--light error--text">
                <span>กรุณาระบุเวลา</span>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pb-md-3 pb-sm-0 pb-0">
              <p class="mb-0 text--bold--14 government--form--label">
                เวลาสิ้นสุดติดตั้ง<span class="red--text">*</span>
              </p>
              <TimePicker @submitTime="endSetupTimeData($event)"></TimePicker>
              <div v-if="!endSetupTimeCheck" class="v-messages theme--light error--text">
                <span>กรุณาระบุเวลา</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" class="pb-md-3 pb-sm-0 pb-0">
              <v-menu
                ref="startRemoveDateDialog"
                v-model="startRemoveDateModal"
                :close-on-content-click="true"
                :return-value.sync="startRemoveDate"
                transition="scale-transition"
                offset-y
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrStartRemoveDate }">
                  <p class="mb-0 text--bold--14 government--form--label">
                    วันรื้อถอนสมบูรณ์<span class="red--text">*</span>
                  </p>
                  <v-text-field
                    :value="formatStartRemoveDate"
                    outlined
                    readonly
                    v-bind="attrStartRemoveDate"
                    v-on="on"
                    dense
                    class="text--regular--14"
                    required
                    :rules="[v => !!v || 'กรุณาระบุ วันรื้อถอนสมบูรณ์']"
                  >
                    <template v-slot:append>
                      <v-fade-transition leave-absolute>
                        <img
                          width="24"
                          height="24"
                          src="../assets/icons/calendar.svg"
                          alt=""
                        />
                      </v-fade-transition>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  id="date-specify--custom"
                  v-model="startRemoveDate"
                  no-title
                  scrollable
                  locale="th-TH"
                  color="primary"
                  :allowed-dates="getAllowedStartRemoveDates"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pb-md-3 pb-sm-0 pb-0">
              <p class="mb-0 text--bold--14 government--form--label">
                เวลาเริ่มรื้อถอน<span class="red--text">*</span>
              </p>
              <TimePicker @submitTime="startRemoveTimeData($event)"></TimePicker>
              <div v-if="!startRemoveTimeCheck" class="v-messages theme--light error--text">
                <span>กรุณาระบุเวลา</span>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pb-md-3 pb-sm-0 pb-0">
              <p class="mb-0 text--bold--14 government--form--label">
                เวลาสิ้นสุดรื้อถอน<span class="red--text">*</span>
              </p>
              <TimePicker @submitTime="endRemoveTimeData($event)"></TimePicker>
              <div v-if="!endRemoveTimeCheck" class="v-messages theme--light error--text">
                <span>กรุณาระบุเวลา</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" md="12" class="pb-md-0 pb-sm-0 pb-0">
              <p class="mb-0 text--bold--14 government--form--label">
                กำหนดการจัดงาน
              </p>
            </v-col>
            <v-col cols="12" xs="5" md="5" class="pb-md-3 pb-sm-0 pb-0">
              <v-text-field
                class="text--regular--placeholder--14 disabled"
                :value="startDate"
                single-line
                outlined
                dense
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="2" md="2" class="pb-md-3 pb-sm-0 pb-0 text-center">
              <p class="mb-0 text--bold--14 government--form--label">
                ถึง
              </p>
            </v-col>
            <v-col cols="12" xs="5" md="5" class="pb-md-3 pb-sm-0 pb-0">
              <v-text-field
                class="text--regular--placeholder--14 disabled"
                :value="endDate"
                single-line
                outlined
                dense
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" class="pb-md-3 pb-sm-0 pb-0">
              <p class="mb-0 text--bold--14 government--form--label">
                รวมระยะเวลาจัดงานทั้งหมด
              </p>
              <v-text-field
                class="text--regular--placeholder--14 disabled"
                :value="totalDay"
                single-line
                outlined
                dense
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pb-md-3 pb-sm-0 pb-0">
              <p class="mb-0 text--bold--14 government--form--label">
                เวลาเริ่มจัดงาน<span class="red--text">*</span>
              </p>
              <TimePicker @submitTime="startEventTimeData($event)"></TimePicker>
              <div v-if="!startEventTimeCheck" class="v-messages theme--light error--text">
                <span>กรุณาระบุเวลา</span>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="3" class="pb-md-3 pb-sm-0 pb-0">
              <p class="mb-0 text--bold--14 government--form--label">
                เวลาสิ้นสุดจัดงาน<span class="red--text">*</span>
              </p>
              <TimePicker @submitTime="endEventTimeData($event)"></TimePicker>
              <div v-if="!endEventTimeCheck" class="v-messages theme--light error--text">
                <span>กรุณาระบุเวลา</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="12" md="12" class="pb-md-0 pb-sm-0 pb-0">
              <p class="mb-0 text--bold--14 government--form--label">
                อุปกรณ์ที่คาดว่าจะต้องใช้
              </p>
              <v-textarea
                class="text--regular--placeholder--14"
                v-model="form.equipment"
                placeholder="อุปกรณ์ที่คาดว่าจะต้องใช้"
                rows="5"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row><v-row>
            <v-col cols="12" xs="12" md="12" class="pb-md-0 pb-sm-0 pb-0">
              <p class="mb-0 text--bold--14 government--form--label">
                จำนวนที่จอดรถยนต์ (คาดการณ์)<span class="red--text">*</span>
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="8" md="6" class="pb-md-0 pb-sm-0 pb-0">
              <v-text-field
                type="number"
                class="text--regular--placeholder--14"
                v-model="form.parking"
                :rules="parkingRules"
                placeholder="จำนวนที่จอดรถยนต์"
                required
                single-line
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="4" md="6" class="pb-md-0 pb-sm-0 pb-0">
              <p class="mb-0 text--bold--14 government--form--label">
                คัน
              </p>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapState, mapActions } from "vuex";
import _get from "lodash/get";

import departmentApi from "../api/internaldepartment";
import groupApi from "../api/internalgroup";

import TimePicker from "./TimePicker";
import moment from "moment";


export default {
  directives: { mask },
  components: {
    TimePicker
  },
  data: () => ({
    startSetupTimeCheck: false,
    endSetupTimeCheck: false,
    startRemoveTimeCheck: false,
    endRemoveTimeCheck: false,
    startEventTimeCheck: false,
    endEventTimeCheck: false,
    startSetupDate: '',
    startSetupDateModal: false,
    startSetupTime: '',
    endSetupTime: '',
    startRemoveDate: '',
    startRemoveDateModal: false,
    startRemoveTime: '',
    endRemoveTime: '',
    departmentItemList: [],
    groupItemList: [],
    groupAll: [],
    isSubmit: false,
    reveal: false,
    valid: true,
    name: "",
    form: {
      zipcode: "",
      thname: "",
      enname: "",
      address: "",
      subdistrict: "",
      province: "",
      district: "",
      mobile: "",
      activities: "",
      activityImageList: [],
      thOrgName: "",
      enOrgName: "",
      agencyType: "",
      department_name: "",
      group_name: "",
      tel: "",
      setup_start_date: "",
      setup_start_time: "",
      setup_end_time: "",
      remove_start_date: "",
      remove_start_time: "",
      remove_end_time: "",
      event_start_time: "",
      event_end_time: "",
      equipment: "",
      parking: ""
    },
    telRules: [
      v => {
        return /^[0-9]{9}$/i.test(v) || " กรุณาระบุเบอร์โทรศัพท์ ให้ถูกต้อง";
      }
    ],
    mobileRules: [
      v => {
        return /^[0-9]{10}$/i.test(v) || " กรุณาระบุเบอร์โทรศัพท์ ให้ถูกต้อง";
      }
    ],
    parkingRules: [
      v => {
        return /^\d+$/i.test(v) || " กรุณาระบุจำนวนที่จอดรถยนต์ ให้ถูกต้อง";
      }
    ],
    emailRules: [
      v => !!v || "กรุณา ระบุอีเมล",
      v => /.+@.+\..+/.test(v) || "กรุณาระบุ รหัสผ่านให้ถูกต้อง"
    ],
    thaiNameRule: [
      v => {
        if (v) {
          return (
            /^[0-9ก-๏\s]+$/i.test(v) ||
            "กรุณาระบุ ชื่อ - นามสกุล (ภาษาไทย) ให้ถูกต้อง"
          );
        } else {
          return false || "กรุณาระบุ ชื่อ - นามสกุล (ภาษาไทย) ให้ถูกต้อง";
        }
      }
    ],
  }),
  watch: {
    startRemoveDate() {
      this.form.remove_start_date = this.startRemoveDate;
      this.$refs.startRemoveDateDialog.save(this.startRemoveDate);
    },
    startSetupDate() {
      this.form.setup_start_date = this.startSetupDate;
      this.$refs.startSetupDateDialog.save(this.startSetupDate);
    }
  },
  computed: mapState({
    formatStartSetupDate() {
      if(this.startSetupDate != '')
      {
        let startSetupDate = new Date(this.startSetupDate);
        return new Date(
          startSetupDate.getFullYear(),
          startSetupDate.getMonth(),
          startSetupDate.getDate()
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
      }
      else
      {
        return '';
      }
    },
    formatStartRemoveDate() {
      if(this.startRemoveDate != '')
      {
        let startRemoveDate = new Date(this.startRemoveDate);
        return new Date(
          startRemoveDate.getFullYear(),
          startRemoveDate.getMonth(),
          startRemoveDate.getDate()
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
      }
      else
      {
        return '';
      }
    },
    areaspecify: state => {
        let room = state.areaspecify.areaCart;
        let room_name = [];

        for(var i = 0; i < room.length; i++) {
          room_name.push(room[i].name);
        }

        return room_name.join(', ');
      },
      minDate: state => {
        return state.datespecify.startDate;
      },
    startDate: state => {
        return new Date(
          state.datespecify.startDate
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
      },
    endDate: state => {
        return new Date(
          state.datespecify.endDate
        ).toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric"
        });
      },
    totalDay: state => {
        let date_1 = new Date(state.datespecify.startDate);
        let date_2 = new Date(state.datespecify.endDate);
        let difference = date_2.getTime() - date_1.getTime();
        return Math.ceil((difference + 1) / (1000 * 3600 * 24)) + ' วัน';
      },
    orgItemList: state => {
      let orgList = _get(
        state,
        "rentspacecriteria.applicantSelected.organizationsTypes",
        ""
      );
      if (orgList) {
        return orgList;
      } else {
        return [];
      }
    }
  }),
  async mounted() {
    const department = await departmentApi.initPage({});
    const group = await groupApi.initPage({});

    this.departmentItemList = [];
    this.groupAll = [];

    for(var n = 0; n < department.data.length; n++)
    {
      this.departmentItemList.push(department.data[n].title);
    }

    for(var i = 0; i < group.data.length; i++)
    {
      for(var j = 0; j < department.data.length; j++)
      {
        if(department.data[j].id == group.data[i].internaldepartment_id)
        {
          this.groupAll.push({department: department.data[j].title, title: group.data[i].title});
        }
      }
    }
  },
  methods: {
    startSetupTimeData: function(e) {
      this.startSetupTimeCheck = /^[0-9]{2}:[0-9]{2}$/i.test(e);
      this.startSetupTime = e;
      this.form.setup_start_time = e;
    },
    endSetupTimeData: function(e) {
      this.endSetupTimeCheck = /^[0-9]{2}:[0-9]{2}$/i.test(e);
      this.endSetupTime = e;
      this.form.setup_end_time = e;
    },
    startRemoveTimeData: function(e) {
      this.startRemoveTimeCheck = /^[0-9]{2}:[0-9]{2}$/i.test(e);
      this.startRemoveTime = e;
      this.form.remove_start_time = e;
    },
    endRemoveTimeData: function(e) {
      this.endRemoveTimeCheck = /^[0-9]{2}:[0-9]{2}$/i.test(e);
      this.endRemoveTime = e;
      this.form.remove_end_time = e;
    },
    startEventTimeData: function(e) {
      this.startEventTimeCheck = /^[0-9]{2}:[0-9]{2}$/i.test(e);
      this.startEventTime = e;
      this.form.event_start_time = e;
    },
    endEventTimeData: function(e) {
      this.endEventTimeCheck = /^[0-9]{2}:[0-9]{2}$/i.test(e);
      this.endEventTime = e;
      this.form.event_end_time = e;
    },
    getAllowedStartSetupDates(val) {
      return moment(val).isSameOrAfter(moment(this.minDate), "date");
    },
    getAllowedStartRemoveDates(val) {
      return moment(val).isSameOrAfter(moment(this.startSetupDate), "date");
    },
    selectInternalDepartment(){

      this.groupItemList = [];

      for(var i = 0; i < this.groupAll.length; i++)
      {
        if(this.form.department_name == this.groupAll[i].department)
        {
          this.groupItemList.push(this.groupAll[i].title);
        }
      }
    },
    ...mapActions("applicantdetail", ["setOrganizationsType", "setRequireDoc"]),
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    setOrganizationsTypeRequireDoc(param) {
      let orgId = _get(param, "id", "");
      this.form.agencyType = _get(param, "organization_name", "");
      let organizationsTypes = _get(
        this.$store.state,
        "rentspacecriteria.applicantSelected.organizationsTypes",
        []
      );
      if (organizationsTypes.length > 0) {
        let filtersRequireDocs = organizationsTypes.filter(o => o.id === orgId);
        if (filtersRequireDocs.length > 0) {
          let getRequireDocs = _get(
            filtersRequireDocs,
            "[0].requireDocs",
            null
          );
          this.setRequireDoc(getRequireDocs);
        }
      }
      this.setOrganizationsType(orgId);
    },
    onUpload(fileName) {
      if (fileName !== null) {
        this.form.activityImageList = this.form.activityImageList.concat(
          fileName
        );
      }
    },
    onRemoveFile(index) {
      if (index !== null && index !== undefined) {
        this.form.activityImageList.splice(index, 1);
      }
    },
    onSubmitForm() {

      this.form.parking = parseInt(this.form.parking);
        let validate = this.$refs.form.validate();

      if(this.startSetupTimeCheck == false || this.endSetupTimeCheck == false || this.startRemoveTimeCheck == false || this.endRemoveTimeCheck == false || this.startEventTimeCheck == false || this.endEventTimeCheck == false)
      {
        this.isSubmit = false;
        return false;
      }
      else
      {
        this.isSubmit = true;
        return validate;
      }
    }
  }
};
</script>

<style scoped>
.guestinfo--upload--container {
  position: relative;
  width: 110px;
  height: 110px;
  border: 2px dashed #d1d1d1;
}
.guestinfo--upload--icon--plus--container {
  position: absolute;
  top: 40%;
  left: 40%;
}

.government--form--label {
  margin-bottom: 10px !important;
}

@media (max-width: 480px) {
  .government--info--card {
    box-shadow: 0px 0px 15px #496c7733 !important;
    border-radius: 10px !important;
  }
  .government--info--container {
    padding: 27px 17px 45px 18px !important;
  }
  .private--info--title {
    margin-bottom: 10px;
  }
}

@media (min-width: 481px) {
  .government--info--card {
    box-shadow: 0px 0px 15px #496c7733 !important;
    border-radius: 10px !important;
  }
  .private--info--title {
    margin-bottom: 19px;
  }
  .government--info--container {
    padding: 30px !important;
  }
}

</style>
